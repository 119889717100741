import { PageTypeEnum, ReportType } from "utils/reportDefinitions";
import { StringResources } from "./languageResource";

export const translationsEn = {
    [StringResources.modal.close]: "Close",
    [StringResources.modal.confirm]: "Confirm",
    [StringResources.modal.delete]: "Delete",
    [StringResources.modal.cancel]: "Cancel",
    [StringResources.modal.set]: "Set",
    [StringResources.modal.details]: "Details",
    [StringResources.modal.submit]: "Submit",
    [StringResources.modal.feedback.title]: "Feedback",
    [StringResources.modal.feedback.label]: "Message",
    [StringResources.modal.feedback.placeholder]: "Type your reply here",
    [StringResources.modal.feedback.success]: "Feedback message has been sent successfully",
    [StringResources.modal.feedback.error]: "Feedback message didn't sent",
    [StringResources.modal.feedback.message]:
        "Straumur support will see this Feedback message and we will reach out to you.",
    [StringResources.modal.column]: "Column",
    [StringResources.modal.filter]: "Filter",
    [StringResources.modal.deleteModal.title]: "Confirm Delete",
    [StringResources.modal.deleteModal.message]: "Are you sure you want to delete this item?",
    [StringResources.modal.refundTransaction.title]: "Refund transaction?",
    [StringResources.modal.refundTransaction.message]:
        "The transaction will be refunded to the customer. This action cannot be undone.",
    [StringResources.modal.refundTransaction.refund]: "Refund",
    [StringResources.modal.refundTransaction.fullRefund]: "Full refund",
    [StringResources.modal.refundTransaction.partialRefund]: "Partial refund",
    [StringResources.modal.refundTransaction.formRefundAmount]: "Refund amount",
    [StringResources.modal.refundTransaction.formRefundAmountRequired]: "Field is required",
    [StringResources.modal.refundTransaction.formRefundAmountMin]: "Must be greater than or equal {{min}}",
    [StringResources.modal.refundTransaction.formRefundAmountMax]: "Must be less or equal {{max}}",
    [StringResources.modal.refundTransaction.maxAmountForRefund]: "Max amount for refund: {{maxAmount}}",
    [StringResources.modal.refundTransaction.amountForRefund]: "Amount for refund: {{amount}}",
    [StringResources.modal.refundTransaction.success]: "Transaction has been refunded successfully",
    [StringResources.modal.refundTransaction.error]: "Error occurred while refunding transaction",
    [StringResources.modal.refundTransaction.confirmRefund]: "Confirm refund",
    [StringResources.modal.refundTransaction.confirmRefundMessage]:
        "Are you sure you want to refund this transaction? You will refund {{amount}} of the {{maxAmount}} to the customer.",

    [StringResources.modal.reversalAuthorization.reversal]: "Reversal",
    [StringResources.modal.reversalAuthorization.success]: "Authorization has been reversed successfully",
    [StringResources.modal.reversalAuthorization.error]: "Error occurred while reversing authorization",
    [StringResources.modal.reversalAuthorization.confirmReversalMessage]:
        "You will reverse {{amount}}\nAre you sure you want to reverse this authorization?",

    [StringResources.errorBoundary.title]: "Oops something went wrong!",
    [StringResources.errorBoundary.subtitle]: "(Refreshing in {{restartInterval}})",
    [StringResources.errorBoundary.btnText]: "Refresh now",

    [StringResources.mainNavigation.dashboard]: "Dashboard",
    [StringResources.mainNavigation.payments]: "Payments",
    [StringResources.mainNavigation.agreements]: "Agreements",
    [StringResources.mainNavigation.settlements]: "Settlements",
    [StringResources.mainNavigation.transactions]: "Transactions",
    [StringResources.mainNavigation.users]: "Users",
    [StringResources.mainNavigation.notifications]: "Notifications",
    [StringResources.mainNavigation.settings]: "Settings",
    [StringResources.mainNavigation.logout]: "Log out",
    [StringResources.mainNavigation.merchantAgreements]: "Merchant Agreements",
    [StringResources.mainNavigation.merchantSettlements]: "Merchant Settlements",
    [StringResources.userMenu.back]: "Back",
    [StringResources.filter.buttonText]: "Filter",
    [StringResources.filter.totalSubtotal]: "Total and subtotal",
    [StringResources.filter.clear]: "Clear",
    [StringResources.filter.search]: "Search",
    [StringResources.filter.dropdownSearchPlaceholder]: "Type to search",
    [StringResources.filter.currency]: "Currency",
    [StringResources.filter.allMerchants]: "All merchants",
    [StringResources.columnChooser.column]: "Column",
    [StringResources.columnChooser.unsavedChanges]: "You have unsaved changes!",
    [StringResources.columnChooser.save]: "Save & close",
    [StringResources.columnChooser.cancel]: "Cancel",
    [StringResources.columnChooser.selectAll]: "Select all",
    [StringResources.columnChooser.defaultView]: "Reset to default",

    [StringResources.reportDetails.boolean.yesLabel]: "Yes",
    [StringResources.reportDetails.boolean.noLabel]: "No",
    [StringResources.table.cell.boolean.yesLabel]: "Yes",
    [StringResources.table.cell.boolean.noLabel]: "No",
    [StringResources.table.fallbackMessage.default]: "No {{pageName}} found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.agreements]: "No agreements found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.merchantAgreements]: "No agreements found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.payments]: "No payments found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.settlements]: "No settlements found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.merchantSettlements]:
        "No merchant settlements found{{fallbackMessageAddon}}",
    [StringResources.table.fallbackMessage.transactions]: "No transactions found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.employees]: "No employees found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.none]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.dashboard]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.notFound]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessageWithFilter]: " - try adjusting your filter settings",
    [StringResources.table.fallbackMessage.filtersRequired]: "Please select at least one filter",
    [StringResources.table.itemsPerPage]: "Items per page",
    [StringResources.report.export]: "Export data",
    [StringResources.report.print]: "Print",
    [StringResources.toolTip.payments.searchBy]: "Search by:",
    [StringResources.toolTip.payments.agreementNumber]: "Agreement number",
    [StringResources.toolTip.payments.dbaNames]: "DBA name(s)",
    [StringResources.toolTip.payments.settlements]: "Settlements",
    [StringResources.toolTip.payments.paymentPeriod]: "Payment is settling transactions within this period.",
    [StringResources.toolTip.settlements.searchBy]: "Search by:",
    [StringResources.toolTip.settlements.settlementNumber]: "Settlement number",
    [StringResources.toolTip.settlements.agreementId]: "Agreement ID",
    [StringResources.toolTip.settlements.dbaNames]: "DBA name(s)",

    [StringResources.toolTip.merchantSettlements.searchBy]: "Search by:",
    [StringResources.toolTip.merchantSettlements.agreementNumber]: "Agreement number",
    [StringResources.toolTip.merchantSettlements.settlementNumber]: "Settlement number",
    [StringResources.toolTip.merchantSettlements.dbaNames]: "DBA name(s)",

    [StringResources.toolTip.transactions.searchBy]: "Search by:",
    [StringResources.toolTip.transactions.transactionId]: "Transaction ID",
    [StringResources.toolTip.transactions.authNumber]: "Authorization number",
    [StringResources.toolTip.transactions.arn]: "ARN",
    [StringResources.toolTip.transactions.terminalId]: "Terminal ID",
    [StringResources.toolTip.transactions.physicalTerminalId]: "Physical terminal ID",
    [StringResources.toolTip.transactions.batchNumber]: "Batch number",
    [StringResources.toolTip.transactions.settlementNumber]: "Settlement number",
    [StringResources.toolTip.transactions.agreementId]: "Agreement ID",
    [StringResources.toolTip.transactions.partnerId]: "Partner ID",
    [StringResources.toolTip.transactions.externalTransactionId]: "External transaction ID",

    [StringResources.pages.agreements.title]: "Agreements",
    [StringResources.pages.agreements.viewSettlements]: "View settlements",
    [StringResources.pages.agreements.agreementNumber]: "Agreement number",
    [StringResources.pages.agreements.partnerAgreementDescription]: "Partner Agreement description",
    [StringResources.pages.agreements.partnerAgreementNumber]: "Partner Agreement",
    [StringResources.pages.agreements.createdTime]: "Created time",
    [StringResources.pages.agreements.type]: "Type",
    [StringResources.pages.agreements.status]: "Status",
    [StringResources.pages.agreements.currency]: "Currency",
    [StringResources.pages.agreements.currencyCode]: "Currency code",
    [StringResources.pages.agreements.agreementBalance]: "Agreement balance",
    [StringResources.pages.agreements.totalBalance]: "Total balance",
    [StringResources.pages.agreements.merchantNumber]: "Merchant number",
    [StringResources.pages.agreements.merchantName]: "Merchant",
    [StringResources.pages.agreements.merchantRegistrationNumber]: "Registration number",
    [StringResources.pages.agreements.isCardPresent]: "Agreement type",
    [StringResources.pages.agreements.description]: "Description",
    [StringResources.pages.agreements.unpaidCommission]: "Unpaid commission",
    [StringResources.pages.agreements.totalCommission]: "Total commission",
    [StringResources.pages.agreements.totalFixedAmount]: "Total fixed amount",
    [StringResources.pages.agreements.totalPercentage]: "Total percentage",
    [StringResources.pages.agreements.viewSettlements]: "View settlements",
    [StringResources.pages.agreements.cardPresent]: "POS",
    [StringResources.pages.agreements.cardNotPresent]: "Ecom",

    [StringResources.pages.agreements.solution]: "Solution",

    [StringResources.pages.merchantAgreements.title]: "Merchant Agreements",
    [StringResources.pages.merchantAgreements.viewSettlements]: "View settlements",
    [StringResources.pages.merchantAgreements.agreementNumber]: "Agreement number",
    [StringResources.pages.merchantAgreements.createdTime]: "Created time",
    [StringResources.pages.merchantAgreements.type]: "Type",
    [StringResources.pages.merchantAgreements.status]: "Status",
    [StringResources.pages.merchantAgreements.currency]: "Currency",
    [StringResources.pages.merchantAgreements.currencyCode]: "Currency code",
    [StringResources.pages.merchantAgreements.agreementBalance]: "Agreement balance",
    [StringResources.pages.merchantAgreements.totalBalance]: "Total balance",
    [StringResources.pages.merchantAgreements.merchantNumber]: "Merchant number",
    [StringResources.pages.merchantAgreements.merchantName]: "Merchant",
    [StringResources.pages.merchantAgreements.merchantRegistrationNumber]: "Registration number",
    [StringResources.pages.merchantAgreements.isCardPresent]: "Agreement type",
    [StringResources.pages.dashboard.title]: "Dashboard",
    [StringResources.pages.dashboard.date]: "Date",
    [StringResources.pages.dashboard.setSalesTarget]: "Set sales target",
    [StringResources.pages.dashboard.targetAmount]: "Target amount",

    [StringResources.pages.payments.title]: "Payments",
    [StringResources.pages.payments.breakdown]: "Settlement breakdown",
    [StringResources.pages.payments.breakdownNoRecords]: "No records found.",
    [StringResources.pages.payments.settlement]: "Settlement",
    [StringResources.pages.payments.netPurchase]: "Net purchase",
    [StringResources.pages.payments.payout]: "Payout",
    [StringResources.pages.payments.search]: "Search payments",
    [StringResources.pages.payments.dateFrom]: "Date from",
    [StringResources.pages.payments.dateTo]: "Date to",
    [StringResources.pages.payments.amountFrom]: "Amount from",
    [StringResources.pages.payments.amountTo]: "Amount to",
    [StringResources.pages.payments.agreementNumber]: "Agreement number",
    [StringResources.pages.payments.currency]: "Currency",
    [StringResources.pages.payments.swift]: "Swift",
    [StringResources.pages.payments.merchantName]: "Merchant",
    [StringResources.pages.payments.iban]: "IBAN",
    [StringResources.pages.payments.payoutAmount]: "Payout amount",
    [StringResources.pages.payments.feeAmounts]: "Fee amount",
    [StringResources.pages.payments.grossAmounts]: "Gross amount",
    [StringResources.pages.payments.paidDate]: "Paid date",
    [StringResources.pages.payments.scheduled]: "Scheduled",
    [StringResources.pages.payments.partnerId]: "Partner ID",
    [StringResources.pages.payments.partnerName]: "Partner name",
    [StringResources.pages.payments.dbaNames]: "DBA names",
    [StringResources.pages.payments.ssn]: "SSN",
    [StringResources.pages.payments.settlementType]: "Settlement type",
    [StringResources.pages.payments.settlements]: "Settlements",
    [StringResources.pages.payments.printReportError]: "Download error",
    [StringResources.pages.payments.agreement]: "Agreement",
    [StringResources.pages.payments.merchant]: "Merchant",
    [StringResources.pages.payments.paymentPeriod]: "Payment period",
    [StringResources.pages.settlements.title]: "Settlements",
    [StringResources.pages.settlements.breakdown]: "Breakdown",
    [StringResources.pages.settlements.search]: "Search settlements",
    [StringResources.pages.settlements.paidDateFrom]: "Paid date from",
    [StringResources.pages.settlements.paidDateTo]: "Paid date to",
    [StringResources.pages.settlements.amountFrom]: "Amount from",
    [StringResources.pages.settlements.amountTo]: "Amount to",
    [StringResources.pages.settlements.allAgreements]: "All agreements",
    [StringResources.pages.settlements.currency]: "Currency",
    [StringResources.pages.settlements.grossPurchase]: "Gross purchase",
    [StringResources.pages.settlements.grossSettlement]: "Gross settlement",
    [StringResources.pages.settlements.feeAmount]: "Fee amount",
    [StringResources.pages.settlements.totalFees]: "Total fees",
    [StringResources.pages.settlements.netSettlement]: "Net settlement",
    [StringResources.pages.settlements.commissionAmount]: "Commission amount",
    [StringResources.pages.settlements.percentageAmount]: "Percentage amount",
    [StringResources.pages.settlements.fixedAmount]: "Fixed amount",
    [StringResources.pages.settlements.batches]: "Batches",
    [StringResources.pages.settlements.viewBatches]: "View batches",
    [StringResources.pages.settlements.viewTransactions]: "View transactions",
    [StringResources.pages.settlements.dbaName]: "DBA name",
    [StringResources.pages.settlements.gross]: "Gross",
    [StringResources.pages.settlements.netPurchase]: "Net purchase",
    [StringResources.pages.settlements.date]: "Date",
    [StringResources.pages.settlements.batch]: "Batch",
    [StringResources.pages.settlements.batchNoRecords]: "No records found.",
    [StringResources.pages.settlements.batchTotal]: "Batch total",
    [StringResources.pages.settlements.batchNumber]: "Batch number",
    [StringResources.pages.settlements.status]: "Status",
    [StringResources.pages.settlements.settlementFee]: "Settlement fee",
    [StringResources.pages.settlements.paid]: "Paid",
    [StringResources.pages.settlements.agreementId]: "Agreement ID",
    [StringResources.pages.settlements.settlementType]: "Settlement type",
    [StringResources.pages.settlements.refundAmount]: "Refunds",
    [StringResources.pages.settlements.createdDate]: "Date created",
    [StringResources.pages.settlements.scheduledDate]: "Scheduled",
    [StringResources.pages.settlements.settlementNumber]: "Settlement number",
    [StringResources.pages.settlements.batchesCount]: "Batches",
    [StringResources.pages.settlements.transactionsCount]: "Transactions",
    [StringResources.pages.settlements.cumulativeFees]: "Cumulative fees",
    [StringResources.pages.settlements.payout]: "Payout",
    [StringResources.pages.settlements.partnerId]: "Partner ID",
    [StringResources.pages.settlements.partnerName]: "Partner name",
    [StringResources.pages.settlements.deduction]: "Deduction",
    [StringResources.pages.settlements.representment]: "Representments",
    [StringResources.pages.settlements.chargeback]: "Chargeback",
    [StringResources.pages.settlements.rollingReserve]: "Rolling reserves",
    [StringResources.pages.settlements.rollingRelease]: "Rolling release",
    [StringResources.pages.settlements.chargebackFees]: "Chargeback fees",
    [StringResources.pages.settlements.swiftFees]: "Swift fees",
    [StringResources.pages.settlements.transactionFee]: "Transaction fee",
    [StringResources.pages.settlements.joiningFee]: "Joining fee",
    [StringResources.pages.settlements.rejected]: "Rejected",
    [StringResources.pages.settlements.addedSum]: "Added sum",
    [StringResources.pages.settlements.deductedSum]: "Deducted sum",
    [StringResources.pages.settlements.reversals]: "Reversals",
    [StringResources.pages.settlements.preArbitration]: "Pre-arbitration",
    [StringResources.pages.settlements.posRentalFee]: "POS rental fee",
    [StringResources.pages.settlements.exceptionCorrection]: "Exception correction",
    [StringResources.pages.settlements.dccCommission]: "DCC commission",
    [StringResources.pages.settlements.dbaNames]: "DBA names",
    [StringResources.pages.settlements.ssn]: "SSN",
    [StringResources.pages.settlements.claim]: "Claim",
    [StringResources.pages.settlements.claimDueDate]: "Claim due date",
    [StringResources.pages.settlements.aukakronurFee]: "Aukakronur fee",
    [StringResources.pages.settlements.otherFees]: "Other fees",
    [StringResources.pages.settlements.gomobileFee]: "Gomobile fee",
    [StringResources.pages.settlements.gomobileInitialFee]: "Gomobile initial fee",
    [StringResources.pages.settlements.preAuthorizationFee]: "Pre authorization fee",
    [StringResources.pages.settlements.monthlyFee]: "Monthly fee",
    [StringResources.pages.settlements.authorizationFee]: "Authorization fee",
    [StringResources.pages.settlements.minimumMonthlyServiceFee]: "Min. monthly service fee",
    [StringResources.pages.settlements.cardNotPresentFee]: "Card not present fee",
    [StringResources.pages.settlements.pciFee]: "PCI fee",
    [StringResources.pages.settlements.mobileAirTimeFee]: "Mobile airtime fee",
    [StringResources.pages.settlements.ecomGatewayMonthlyFee]: "Ecom gateway monthly fee",
    [StringResources.pages.settlements.ecomGatewayTransactionFee]: "Ecom gateway trans. fee",
    [StringResources.pages.settlements.merchantName]: "Merchant",
    [StringResources.pages.settlements.print]: "Print",
    [StringResources.pages.settlements.printReportError]: "Download error",
    [StringResources.pages.merchantSettlements.ssn]: "SSN",
    [StringResources.pages.merchantSettlements.createdDate]: "Date created",
    [StringResources.pages.merchantSettlements.scheduledDate]: "Scheduled",
    [StringResources.pages.merchantSettlements.grossSettlement]: "Gross settlement",
    [StringResources.pages.merchantSettlements.netSettlement]: "Net settlement",
    [StringResources.pages.merchantSettlements.currency]: "Currency",
    [StringResources.pages.merchantSettlements.dbaNames]: "DBA name",
    [StringResources.pages.merchantSettlements.agreementId]: "Agreement ID",
    [StringResources.pages.merchantSettlements.status]: "Status",
    [StringResources.pages.merchantSettlements.paid]: "Paid",
    [StringResources.pages.merchantSettlements.settlementNumber]: "Settlement number",
    [StringResources.pages.merchantSettlements.settlementType]: "Settlement type",
    [StringResources.pages.merchantSettlements.transactionsCount]: "Transactions",
    [StringResources.pages.merchantSettlements.batchesCount]: "Batches",
    [StringResources.pages.merchantSettlements.netPurchase]: "Net purchase",
    [StringResources.pages.merchantSettlements.feeAmount]: "Fee amount",
    [StringResources.pages.merchantSettlements.refundAmount]: "Refund amount",
    [StringResources.pages.merchantSettlements.representment]: "Representments",
    [StringResources.pages.merchantSettlements.chargeback]: "Chargeback",
    [StringResources.pages.merchantSettlements.rejected]: "Rejected",
    [StringResources.pages.merchantSettlements.reversals]: "Reversals",
    [StringResources.pages.merchantSettlements.search]: "Search merchant settlements",
    [StringResources.pages.merchantSettlements.allAgreements]: "All agreements",
    [StringResources.pages.merchantSettlements.amountFrom]: "Amount from",
    [StringResources.pages.merchantSettlements.amountTo]: "Amount to",
    [StringResources.pages.merchantSettlements.paidDateFrom]: "Paid date from",
    [StringResources.pages.merchantSettlements.paidDateTo]: "Paid date to",
    [StringResources.pages.merchantSettlements.createdDateFrom]: "Created date from",
    [StringResources.pages.merchantSettlements.createdDateTo]: "Created date to",
    [StringResources.pages.merchantSettlements.title]: "Merchant Settlements",
    [StringResources.pages.merchantSettlements.partnerCommission]: "Partner commission",
    [StringResources.pages.transactions.title]: "Transactions",
    [StringResources.pages.transactions.cardNumber]: "Card number",
    [StringResources.pages.transactions.settlementNumber]: "Settlement number",
    [StringResources.pages.transactions.commissionAmount]: "Commission amount",
    [StringResources.pages.transactions.percentageAmount]: "Percentage amount",
    [StringResources.pages.transactions.fixedAmount]: "Fixed amount",
    [StringResources.pages.transactions.cardType]: "Card Type",
    [StringResources.pages.transactions.cardIssuer]: "Card Issuer",
    [StringResources.pages.transactions.settlementType]: "Settlement Type",
    [StringResources.pages.transactions.transactionId]: "Transaction ID",
    [StringResources.pages.transactions.authorizationDate]: "Authorization date",
    [StringResources.pages.transactions.contractId]: "Agreement ID",
    [StringResources.pages.transactions.dbaName]: "DBA name",
    [StringResources.pages.transactions.referenceData]: "Reference data",
    [StringResources.pages.transactions.purchaseDate]: "Purchase date",
    [StringResources.pages.transactions.originalAmount]: "Original amount",
    [StringResources.pages.transactions.gross]: "Gross",
    [StringResources.pages.transactions.netAmount]: "Net amount",
    [StringResources.pages.transactions.currency]: "Currency",
    [StringResources.pages.transactions.secure3D]: "3D Secure",
    [StringResources.pages.transactions.batch]: "Batch",
    [StringResources.pages.transactions.registrationNumber]: "Registration number",
    [StringResources.pages.transactions.reasonCode]: "Reason code",
    [StringResources.pages.transactions.merchantBucket]: "Merchant bucket",
    [StringResources.pages.transactions.physicalTermianlId]: "Physical terminal ID",
    [StringResources.pages.transactions.lifeCycleId]: "LifeCycle ID",
    [StringResources.pages.transactions.paid]: "Paid",
    [StringResources.pages.transactions.terminalId]: "Terminal ID",
    [StringResources.pages.transactions.arn]: "ARN",
    [StringResources.pages.transactions.fees]: "Fees",
    [StringResources.pages.transactions.originalCurrency]: "Original currency",
    [StringResources.pages.transactions.registrationDate]: "Registration date",
    [StringResources.pages.transactions.transactionType]: "Transaction type",
    [StringResources.pages.transactions.merchantFee]: "Merchant Fee",
    [StringResources.pages.transactions.authorizationCode]: "Authorization code",
    [StringResources.pages.transactions.cardEntryModeType]: "Card entry mode type",
    [StringResources.pages.transactions.authenticationMethodType]: "Authentication method type",
    [StringResources.pages.transactions.authorizationMethodType]: "Authorization method type",
    [StringResources.pages.transactions.isReversal]: "Is reversal",
    [StringResources.pages.transactions.search]: "Search",
    [StringResources.pages.transactions.agreementNumber]: "Agreement number",
    [StringResources.pages.transactions.amountFrom]: "Amount from",
    [StringResources.pages.transactions.amountTo]: "Amount to",
    [StringResources.pages.transactions.purchaseDateFrom]: "Purchase date from",
    [StringResources.pages.transactions.purchaseDateTo]: "Purchase date to",
    [StringResources.pages.transactions.registrationDateFrom]: "Registration date from",
    [StringResources.pages.transactions.registrationDateTo]: "Registration date to",
    [StringResources.pages.transactions.paidDateFrom]: "Paid date from",
    [StringResources.pages.transactions.paidDateTo]: "Paid date to",
    [StringResources.pages.transactions.partnerContractId]: "Partner contract id",
    [StringResources.pages.transactions.partnerSettlementPaymentId]: "Partner settlement payment id",

    [StringResources.pages.employees.title]: "Users",
    [StringResources.pages.employees.permissions]: "Permissions",
    [StringResources.pages.employees.apiKey]: "API Key",
    [StringResources.pages.employees.table.usernameHeaderLabel]: "Email",
    [StringResources.pages.employees.table.statusHeaderLabel]: "Status",
    [StringResources.pages.employees.table.statusActiveLabel]: "Active",
    [StringResources.pages.employees.table.statusInactiveLabel]: "Deactivated",
    [StringResources.pages.employees.table.parnterUserRoleHeaderLabel]: "Role",
    [StringResources.pages.employees.create.buttonText]: "Add user",
    [StringResources.pages.employees.create.title]: "Add new user",
    [StringResources.pages.employees.create.permissions]: "Permissions",
    [StringResources.pages.employees.create.permissionsPage]: "Page",
    [StringResources.pages.employees.create.confirm]: "Add user",
    [StringResources.pages.employees.create.close]: "Close",
    [StringResources.pages.employees.create.formEmailInputLabel]: "Username",
    [StringResources.pages.employees.create.formContractInputLabel]: "Agreements",
    [StringResources.pages.employees.create.formButtonCaption]: "Create",
    [StringResources.pages.employees.create.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.pages.employees.create.formUsernameAlreadyHasAccessValidationMsg]:
        "User already has access to this merchant.",
    [StringResources.pages.employees.create.successMsg]: "Sucessfully added new user",

    [StringResources.pages.employees.edit.title]: "Edit user",
    [StringResources.pages.employees.edit.permissions]: "Permissions",
    [StringResources.pages.employees.edit.permissionsPage]: "Page",
    [StringResources.pages.employees.edit.confirm]: "Edit",
    [StringResources.pages.employees.edit.deactivate]: "Deactivate",
    [StringResources.pages.employees.edit.close]: "Close",
    [StringResources.pages.employees.edit.formContractInputLabel]: "Agreements",
    [StringResources.pages.employees.edit.successMsg]: "Sucessfully edited user",
    [StringResources.pages.employees.edit.errorMsg]: "Error while editing user",
    [StringResources.pages.employees.edit.errorApiKeyMsg]: "Error while fetching API key.",
    [StringResources.pages.employees.edit.successDeactivateMsg]: "Sucessfully deactivated user",

    [StringResources.pages.employees.details.employeeId]: "ID",
    [StringResources.pages.employees.details.username]: "Username",
    [StringResources.pages.employees.details.status]: "Status",
    [StringResources.pages.employees.details.role]: "Role",

    [StringResources.pages.notFound.title]: "Page not found",
    [StringResources.pages.notFound
        .description]: `The page you are trying to view does not exist or you do not have permission to view it.`,
    [StringResources.pages.notFound.link]: "Back to partner portal",
    [StringResources.pages.none.title]: "None",
    [StringResources.pages.settings.title]: "Settings",
    [StringResources.pages.settings.generalTab]: "General",
    [StringResources.pages.settings.emailsAndNotificationsTab]: "Emails & notifications",

    [StringResources.pages.settings.changePasswordTab]: "Change password",
    [StringResources.pages.settings.accountAndSecurity.title]: "Account and Security",
    [StringResources.pages.settings.accountAndSecurity.details]: "Details",
    [StringResources.pages.settings.accountAndSecurity.changePasswordTitle]: "Change password",
    [StringResources.pages.settings.accountAndSecurity.username]: "Signed in as:",
    [StringResources.pages.settings.general.defaultLanguage]: "Default language",
    [StringResources.pages.settings.changePassword.oldPassword]: "Old password",
    [StringResources.pages.settings.changePassword.newPassword]: "New password",
    [StringResources.pages.settings.changePassword.repeatNewPassword]: "Repeat new password",
    [StringResources.pages.settings.changePassword.saveNewPassword]: "Save new password",
    [StringResources.pages.settings.changePassword.confirmNewPassword]: "Confirm new password",
    [StringResources.pages.settings.changePassword.currentPassword]: "Current password",
    [StringResources.pages.settings.emailsAndNotifications.statementsTitle]: "Statements",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioTitle]:
        "Receive an email notification when a statement is generated. You will only receive statements for agreements you have permission to view",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioPdfEmail]:
        "Email with statement document (pdf)",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNotificationEmail]:
        "Email notification only (no document)",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNoEmail]: "No email",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioError]:
        "Error while updating notification settings",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioSuccess]:
        "Successfully updated notification settings",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].title]: "Partner contract",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].details]: "Details",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].unpaidCommission]: "Unpaid commission",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].description]: "Description",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].partnerContractStatus]: "Partner contract status",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].partnerContractNumber]: "Partner contract number",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].settlementCurrency]: "Settlement currency",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].detailsAndUnpaidCommission]:
        "Details, unpaid commission",

    [StringResources.export.confirm]: "Export",
    [StringResources.export.title]: "Export",
    [StringResources.export.close]: "Cancel",
    [StringResources.export.exportType]: "Export type",
    [StringResources.export.exportTypeCSV]: "CSV",
    [StringResources.export.exportTypeXLSX]: "XLSX",
    [StringResources.export.exportColumn]: "Select Data",
    [StringResources.export.exportAllColumns]: "All columns available",
    [StringResources.export.exportVisibleColumns]: "Currently visible columns",
    [StringResources.export.exportSuccess]: "Download successful",
    [StringResources.export.exportError]: "Download failed",
    [StringResources.export.exportInfo]: "In progress",
    [StringResources.export.exportToManyRows]:
        "Export exceeds {{maxItems}} lines. Please adjust the filter and try again.",
    [StringResources.input.passwordShowCaption]: "Show",
    [StringResources.input.passwordHideCaption]: "Hide",
    [StringResources.input.passwordStrengthBarLabel]: "Password strength",
    [StringResources.input.passwordStrengthBad]: "Bad",
    [StringResources.input.passwordStrengthWeak]: "Weak",
    [StringResources.input.passwordStrengthPassable]: "Passable",
    [StringResources.input.passwordStrengthGood]: "Good",
    [StringResources.input.passwordStrengthVeryGood]: "Very Good",
    [StringResources.login.backActionLabel]: "Back to web",
    [StringResources.login.title]: "Partner Portal",
    [StringResources.login.formTitle]: "Log in",
    [StringResources.login.formImpersonateTitle]: "Log in (Admin Impersonate Login)",
    [StringResources.login.formEmailInputLabel]: "Email",
    [StringResources.login.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.login.formPasswordInputLabel]: "Password",
    [StringResources.login.formButtonCaption]: "Log in",
    [StringResources.login.forgotPasswordLink]: "Forgot your password?",
    [StringResources.login.loginFailed]: "You have entered an invalid username or password",
    [StringResources.forgotPassword.title]: "Reset password?",
    [StringResources.forgotPassword.description]:
        "Please enter the email of your partner portal account. You will receive an email with a link to choose a new password.",
    [StringResources.forgotPassword.formEmailInputLabel]: "Email",
    [StringResources.forgotPassword.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.forgotPassword.formButtonCaption]: "Reset password",
    [StringResources.forgotPassword.backButtonCaption]: "Cancel",
    [StringResources.forgotPassword.formSubmittedTitle]: "Email sent",
    [StringResources.forgotPassword.formSubmittedDescription]:
        "We have sent an email to <strong>{{email}}</strong> with a link to reset your password. Please follow the link to finish resetting your password.",
    [StringResources.forgotPassword.formSubmittedBackButtonCaption]: "Back to log-in",
    [StringResources.resetPassword.title]: "Choose new password",
    [StringResources.resetPassword.formPasswordInputLabel]: "New password",
    [StringResources.resetPassword.formPasswordValidationMsg]: "Password must contain at least 8 characters",
    [StringResources.resetPassword.formConfirmPasswordInputLabel]: "Confirm new password",
    [StringResources.resetPassword.formConfirmPasswordValidationMsg]: "Passwords must match",
    [StringResources.resetPassword.formButtonCaption]: "Submit",
    [StringResources.resetPassword.formSubmittedTitle]: "Password successfully changed",
    [StringResources.resetPassword.formSubmittedDescription]: "You can now log in with your new password.",
    [StringResources.resetPassword.formSubmittedBackButtonCaption]: "Back to log-in",
    [StringResources.confirmAccount.title]: "Welcome to Straumur partner portal",
    [StringResources.confirmAccount.subTitle]: "Choose a password to finish setting up your account.",
    [StringResources.confirmAccount.formDisplayUsernameInputLabel]: "Email:",
    [StringResources.confirmAccount.formPasswordInputLabel]: "Password",
    [StringResources.confirmAccount.formPasswordValidationMsg]: "Password must contain at least 8 characters",
    [StringResources.confirmAccount.formConfirmPasswordInputLabel]: "Confirm password",
    [StringResources.confirmAccount.formConfirmPasswordValidationMsg]: "Passwords must match",
    [StringResources.confirmAccount.formButtonCaption]: "Submit",
    [StringResources.confirmAccount.formSubmittedTitle]: "Your password was set successfully",
    [StringResources.confirmAccount.formSubmittedDescription]: "You can now log in.",
    [StringResources.confirmAccount.formSubmittedBackButtonCaption]: "Back to log-in",
    [StringResources.confirmAccount.contactServiceDesk]:
        "Having trouble creating your account? Contact <hyperLink>{{straumurEmail}}</hyperLink> or call us at <telLink>{{straumurPhone}}</telLink>.",
    [StringResources.multiselect.selected]: "Selected",
    [StringResources.multiselect.options]: "Options",
    [StringResources.multiselect.selectAll]: "Select All",
    [StringResources.multiselect.clearAll]: "Clear",
    [StringResources.dashboard.totalSales]: "Turnover for the month",
    [StringResources.dashboard.totalSalesTooltip]: "Turnover for the selected month",
    [StringResources.dashboard.unpaidCommissionAmount]: "Accumulated commission",
    [StringResources.dashboard.partnerContract]: "Partner Contract",
    [StringResources.dashboard.allPartnerContracts]: "All Partner Contracts",
    [StringResources.dashboard.unpaidCommissionAmountToolTip]: "Accumulated commission for the selected month",
    [StringResources.dashboard.numberOfSales]: "Transactions",
    [StringResources.dashboard.averageNumberOfSales]: "Average transactions",
    [StringResources.dashboard.grossSales]: "Turnover",
    [StringResources.dashboard.averageGrossSales]: "Average turnover",
    [StringResources.dashboard.day]: "Day",
    [StringResources.dashboard.noData]: "No data to display",
    [StringResources.dashboard.exportGraph]: "Export Graph",
    [StringResources.dashboard.january]: "January",
    [StringResources.dashboard.february]: "February",
    [StringResources.dashboard.march]: "March",
    [StringResources.dashboard.april]: "April",
    [StringResources.dashboard.may]: "May",
    [StringResources.dashboard.june]: "June",
    [StringResources.dashboard.july]: "July",
    [StringResources.dashboard.august]: "August",
    [StringResources.dashboard.september]: "September",
    [StringResources.dashboard.october]: "October",
    [StringResources.dashboard.november]: "November",
    [StringResources.dashboard.december]: "December",
    [StringResources.dashboard.salesTileTooltip]:
        "Daily turnover volumes and number of transactions for the selected month",
    [StringResources.dashboard.salesTileTitle]: "Turnover",
    [StringResources.dashboard.turnOverTileTooltip]: "Values showing the trend for the last 12 months",
    [StringResources.dashboard.turnOverTileTitle]: "Trend",
    [StringResources.dashboard.comission]: "Commission",
    [StringResources.dashboard.averageComission]: "Average commission",
    [StringResources.dashboard.month]: "Month",

    [StringResources.versionCheck.text]:
        "We've just released a new update for the app which includes some great new features - so make sure you force update the app to get the latest and greatest by clicking ",
    [StringResources.versionCheck.button]: "here!",

    [StringResources.activityTracker.somethingWentWrong]: "Something went wrong. Please log in again.",
    [StringResources.activityTracker.loggedOutInactivtiy]:
        "You have been logged out due to inactivity. Please log in again.",

    [StringResources.networkStatusIndicator.online]: "You are now online!",
    [StringResources.networkStatusIndicator.offline]:
        "You are not connected to the internet. Please check your network settings.",
    [StringResources.reports[ReportType.Agreements].title]: "Agreements",
    [StringResources.reports[ReportType.Payments].title]: "Payments",
    [StringResources.reports[ReportType.Settlements].title]: "Settlements",
    [StringResources.reports[ReportType.Transactions].title]: "Transactions",
    [StringResources.reports[ReportType.Employees].title]: "Users",
    [StringResources.reports[ReportType.Dashboard].title]: "Dashboard",
    [StringResources.reports[ReportType.NotFound].title]: "Page not found",
    [StringResources.reports[ReportType.MerchantAgreements].title]: "Merchant Agreements",
    [StringResources.reports[ReportType.MerchantSettlements].title]: "Merchant Settlements",
    [StringResources.reports[ReportType.None].title]: "None",
    [StringResources.reports[ReportType.SettlementDefinitions].title]: "Settlement Definitions",
    [StringResources.reports[ReportType.SettlementDefinitions].bankAccountNumber]: "Bank account number",
    [StringResources.reports[ReportType.SettlementDefinitions].validFrom]: "Valid from",
    [StringResources.reports[ReportType.SettlementDefinitions].validTo]: "Valid to",
    [StringResources.reports[ReportType.SettlementDefinitions].settlementCycleStartsOn]: "Settlement cycle starts on",
    [StringResources.reports[ReportType.SettlementDefinitions].dayOfMonth]: "Day of month",
    [StringResources.reports[ReportType.SettlementDefinitions].iban]: "Bank account IBAN",
    [StringResources.reports[ReportType.SettlementDefinitions].externalId]: "Bank account External ID",
    [StringResources.reports[ReportType.SettlementDefinitions].currentDefinitionFilter]:
        "Current settlement definition",
    [StringResources.reports[ReportType.SettlementDefinitions].pendingDefinitionFilter]:
        "Pending settlement definition",
    [StringResources.reports[ReportType.SettlementDefinitions].updatePending]: "Update pending ({{date}})",

    [StringResources.reports[ReportType.CommissionDefinitions].title]: "Commission Definitions",
    [StringResources.reports[ReportType.CommissionDefinitions].validFrom]: "Valid from",
    [StringResources.reports[ReportType.CommissionDefinitions].validTo]: "Valid to",
    [StringResources.reports[ReportType.CommissionDefinitions].percentage]: "Percentage",
    [StringResources.reports[ReportType.CommissionDefinitions].transactionFee]: "Transaction fee",
    [StringResources.reports[ReportType.CommissionDefinitions].currencyId]: "Currency",
    [StringResources.reports[ReportType.CommissionDefinitions].feeTransactionCategoryType]:
        "Fee transaction category type",
    [StringResources.reports[ReportType.CommissionDefinitions].feeBrandType]: "Fee brand type",
    [StringResources.reports[ReportType.CommissionDefinitions].feeCardUsageType]: "Fee card usage type",
    [StringResources.reports[ReportType.CommissionDefinitions].feeCardCategoryType]: "Fee card category type",
    [StringResources.reports[ReportType.CommissionDefinitions].feeTransactionRegionType]: "Fee transaction region type",
    [StringResources.reports[ReportType.CommissionDefinitions].all]: "All",
    [StringResources.reports[ReportType.CommissionDefinitions].updatePending]: "Update pending",
    [StringResources.reports[ReportType.CommissionDefinitions].currentDefinitionFilter]: "Current definition filter",
    [StringResources.reports[ReportType.CommissionDefinitions].pendingDefinitionFilter]: "Pending definition filter",

    [StringResources.reports[ReportType.LinkedMerchantAgreements].title]: "Linked Merchant Agreements",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].contractNumber]: "Agreement number",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].contractStatus]: "Status",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].isCardPresent]: "Agreement type",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].currencyId]: "Currency",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].createdDate]: "Created",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].dbaNames]: "DBA names",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].solution]: "Solution",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].feeCalculationTypes]: "Fee calculation types",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].description]: "Description",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].mccCodes]: "MCC codes",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].linkedTime]: "Linked time",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].lastCreatedSettlementDate]:
        "Last created settlement date",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].searchString]: "Search string",
};
