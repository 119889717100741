import TableCellCurrency from "components/tableCellComponents/tableCellCurrency/tableCellCurrency";
import { t } from "i18next";
import { IGetPaymentsResponseListData } from "pages/payments/api/paymentsModels";
import { ITransactionsFilter } from "pages/transactions/api/transactionModels";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paymentSettlementBreakdownGet } from "redux/actions/reportDetailSectionActions";
import { GetPaymentSettlementsBreakdownResponseData } from "redux/models/reportDetailsSectionModels";
import { setBreakdownsDataFromMap } from "redux/reducers/reportDetailSectionReducer";
import { setDrillFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { nameof } from "ts-simple-nameof";
import { Currency } from "utils/formatter";
import { StringResources } from "utils/language/languageResource";
import { PATHS } from "utils/routing/paths";
import "./paymentSettlementsBreakdown.scss";

const PaymentSettlementsBreakdown = () => {
    const activeRowData: IGetPaymentsResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const dispatch = useAppDispatch();
    const breakdownMap = useSelector((state: RootState) => state.reportDetailSection.settlementsBreakdownMap);
    const breakdowns = useSelector((state: RootState) => state.reportDetailSection.settlementsBreakdown);
    const isLoading = useSelector((state: RootState) => state.reportDetailSection.paymentIsLoading);

    useEffect(() => {
        const request = {
            paymentId: activeRowData.id,
        };

        const breakdownData = breakdownMap.find(obj => obj.paymentId === request.paymentId);

        if (breakdownData === undefined) {
            dispatch(paymentSettlementBreakdownGet(request));
        } else {
            dispatch(setBreakdownsDataFromMap(breakdownData));
        }
    }, [activeRowData]);

    return (
        <div className="payment-settlements-breakdown">
            <div className="payment-settlements-breakdown__title">
                {`${t(StringResources.pages.payments.breakdown)}`}
            </div>

            {isLoading && <Skeleton></Skeleton>}

            {!isLoading && breakdowns.length === 0 && (
                <span className="settlement-batches__no-records">
                    {`${t(StringResources.pages.payments.breakdownNoRecords)}`}
                </span>
            )}

            {!isLoading &&
                breakdowns.map((x, index) => {
                    return (
                        <PartnerContractRow
                            key={x.partnerContractDescription}
                            breakdown={x}
                            currency={activeRowData.currencyId}
                            partnerSettlementPaymentId={activeRowData.id}
                        />
                    );
                })}
        </div>
    );
};

interface PartnerContractRowProps {
    breakdown?: GetPaymentSettlementsBreakdownResponseData;
    currency?: Currency;
    partnerSettlementPaymentId: number;
}

const PartnerContractRow: React.FC<PartnerContractRowProps> = ({
    breakdown = null,
    currency,
    partnerSettlementPaymentId,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onRedirectClickWithDrill = (
        merchantId: number,
        partnerContractId: number,
        partnerSettlementPaymentId: number
    ) => {
        return async () => {
            await dispatch(
                setDrillFilters([
                    {
                        field: nameof<ITransactionsFilter>(o => o.merchantId),
                        value: merchantId,
                    },
                    {
                        field: nameof<ITransactionsFilter>(o => o.partnerContractId),
                        value: partnerContractId,
                    },
                    {
                        field: nameof<ITransactionsFilter>(o => o.partnerSettlementPaymentId),
                        value: partnerSettlementPaymentId,
                    },
                ])
            );

            navigate(PATHS.Portal.Transactions);
        };
    };

    return (
        <div>
            <div className="payment-settlements-breakdown__row--item-label">
                <b>{`${t(StringResources.pages.payments.agreement)}`}</b>
            </div>
            <div>{breakdown?.partnerContractDescription}</div>
            <div className="payment-settlements-breakdown__row--header">
                <div className="payment-settlements-breakdown__row--item-label">
                    <b>{`${t(StringResources.pages.payments.merchant)}`}</b>
                </div>
                <div className="payment-settlements-breakdown__row--item-label">
                    <b>{`${t(StringResources.pages.transactions.commissionAmount)}`}</b>
                </div>
            </div>
            {breakdown?.merchantPayoutData.map(x => (
                <div className="payment-settlements-breakdown__row" role="link">
                    <div className="payment-settlements-breakdown__row--item-value">{x.merchantName}</div>
                    <div
                        className="payment-settlements-breakdown__row--item-value--link"
                        onClick={onRedirectClickWithDrill(
                            x.merchantId,
                            breakdown.partnerContractId,
                            partnerSettlementPaymentId
                        )}
                    >
                        {TableCellCurrency({
                            value: x.commissionAmount,
                            format: currency,
                            options: {
                                formatOptions: currency === "ISK" ? "Symbol" : "Code",
                                specialRules: [],
                            },
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PaymentSettlementsBreakdown;
