import { StringResources } from "utils/language/languageResource";
import { PageTypeEnum, ReportType } from "utils/reportDefinitions";

export const translationsIs = {
    [StringResources.modal.close]: "Hætta við",
    [StringResources.modal.confirm]: "Staðfesta",
    [StringResources.modal.delete]: "Henda",
    [StringResources.modal.cancel]: "Hætta við",
    [StringResources.modal.set]: "Stilla",
    [StringResources.modal.details]: "Nánar",
    [StringResources.modal.submit]: "Senda",
    [StringResources.modal.feedback.title]: "Endurgjöf",
    [StringResources.modal.feedback.label]: "Skilaboð",
    [StringResources.modal.feedback.placeholder]: "Skrifa skilaboð hér",
    [StringResources.modal.feedback.success]: "Skilaboð hafa verið send",
    [StringResources.modal.feedback.error]: "Skilaboð voru ekki send",
    [StringResources.modal.feedback.message]: "Þjónustuver Straums mun hafa samband við fyrsta tækifæri.",
    [StringResources.modal.deleteModal.title]: "Staðfesta eyðingu",
    [StringResources.modal.deleteModal.message]: "Ertu viss um að þú viljir eyða?",
    [StringResources.modal.refundTransaction.title]: "Endurgreiða færslu?",
    [StringResources.modal.refundTransaction.message]:
        "Færslan verður endurgreidd til viðskiptavinarins. Þessa aðgerð er ekki hægt að draga til baka.",
    [StringResources.modal.refundTransaction.refund]: "Endurgreiða",
    [StringResources.modal.refundTransaction.fullRefund]: "Endurgreiða",
    [StringResources.modal.refundTransaction.partialRefund]: "Endurgreiða að hluta",
    [StringResources.modal.refundTransaction.formRefundAmount]: "Upphæð endurgreiðslu",
    [StringResources.modal.refundTransaction.formRefundAmountRequired]: "Nauðsynlegt er að fylla þetta svæði",
    [StringResources.modal.refundTransaction.formRefundAmountMin]: "Upphæð verður að vera hærri en {{min}}",
    [StringResources.modal.refundTransaction.formRefundAmountMax]: "Upphæð verður að vera lægri en  {{max}}",
    [StringResources.modal.refundTransaction.maxAmountForRefund]: "Hámarks upphæð endurgreiðslu: {{maxAmount}}",
    [StringResources.modal.refundTransaction.amountForRefund]: "Upphæð til endurgreiðslu: {{amount}}",
    [StringResources.modal.refundTransaction.success]: "Færslan hefur verið endurgreidd",
    [StringResources.modal.refundTransaction.error]: "Villa kom upp við endurgreiðslu á færslu",
    [StringResources.modal.refundTransaction.confirmRefund]: "Staðfesta endurgreiðslu",
    [StringResources.modal.refundTransaction.confirmRefundMessage]:
        "Ertu viss um að þú viljir endurgreiða þessa færslu? Þú munt endurgreiða {{amount}} af {{maxAmount}} til viðskiptavinarins.",

    [StringResources.modal.reversalAuthorization.reversal]: "Afturköllun",
    [StringResources.modal.reversalAuthorization.success]: "Heimild hefur verið afturkölluð",
    [StringResources.modal.reversalAuthorization.error]: "Villa kom upp við að bakfæra heimild",
    [StringResources.modal.reversalAuthorization.confirmReversalMessage]: "Afturkalla heimild fyrir {{amount}}",

    [StringResources.errorBoundary.title]: "Afsakaðu, eitthvað hefur farið úrskeðis",
    [StringResources.errorBoundary.subtitle]: "(Síðan verður endurræst eftir {{restartInterval}})",
    [StringResources.errorBoundary.btnText]: "Endurræsa núna",

    [StringResources.mainNavigation.dashboard]: "Mælaborð",
    [StringResources.mainNavigation.payments]: "Útborganir",
    [StringResources.mainNavigation.agreements]: "Samningar",
    [StringResources.mainNavigation.settlements]: "Uppgjör",
    [StringResources.mainNavigation.transactions]: "Færslur",
    [StringResources.mainNavigation.users]: "Notendur",
    [StringResources.mainNavigation.notifications]: "Tilkynningar",
    [StringResources.mainNavigation.settings]: "Stillingar",
    [StringResources.mainNavigation.logout]: "Skrá út",
    [StringResources.mainNavigation.merchantAgreements]: "Samningar söluaðila",
    [StringResources.mainNavigation.merchantSettlements]: "Uppgjör söluaðila",
    [StringResources.userMenu.back]: "Til baka",
    [StringResources.filter.buttonText]: "Sía",
    [StringResources.filter.totalSubtotal]: "Samtals",
    [StringResources.filter.clear]: "Hreinsa síu",
    [StringResources.filter.search]: "Leita",
    [StringResources.filter.dropdownSearchPlaceholder]: "Ýttu til að skrifa",
    [StringResources.filter.currency]: "Mynt",
    [StringResources.filter.allMerchants]: "Allir söluaðila",
    [StringResources.columnChooser.column]: "Dálkar",
    [StringResources.columnChooser.unsavedChanges]: "Þú ert með óvistaðar breytingar!",
    [StringResources.columnChooser.save]: "Vista og loka",
    [StringResources.columnChooser.cancel]: "Hætta við",
    [StringResources.columnChooser.selectAll]: "Velja allt",
    [StringResources.columnChooser.defaultView]: "Sjálfgefið útsýni",

    [StringResources.reportDetails.boolean.yesLabel]: "Já",
    [StringResources.reportDetails.boolean.noLabel]: "Nei",
    [StringResources.table.cell.boolean.yesLabel]: "Já",
    [StringResources.table.cell.boolean.noLabel]: "Nei",
    [StringResources.table.fallbackMessage.default]: "Ekkert {{pageName}} fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.agreements]: "Enginn samningur fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.merchantAgreements]: "Enginn samningur fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.payments]: "Engin útbogun fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.settlements]: "Ekkert uppgjör fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.transactions]: "Enginn færsla fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.employees]: "Enginn notandi fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.none]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.dashboard]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.notFound]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessageWithFilter]: " - prufaðu að breyta síu stillingunum",
    [StringResources.table.fallbackMessage.filtersRequired]: "Vinsamlegast veldu að minsta kosti einn valmöguleika",
    [StringResources.table.itemsPerPage]: "Atriði á síðu",
    [StringResources.report.export]: "Sækja gögn",
    [StringResources.report.print]: "Prenta",
    [StringResources.toolTip.payments.searchBy]: "Leita eftir:",
    [StringResources.toolTip.payments.agreementNumber]: "Samningsnúmer",
    [StringResources.toolTip.payments.dbaNames]: "Nafn sölustaðar",
    [StringResources.toolTip.payments.settlements]: "Uppgjör",
    [StringResources.toolTip.payments.paymentPeriod]: "Útborgun gerir upp færslur á þessu tímabili.",
    [StringResources.toolTip.settlements.searchBy]: "Leita eftir:",
    [StringResources.toolTip.settlements.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.toolTip.settlements.agreementId]: "Samningsnúmer",
    [StringResources.toolTip.settlements.dbaNames]: "Nafn sölustaðar",

    [StringResources.toolTip.merchantSettlements.searchBy]: "Leita eftir:",
    [StringResources.toolTip.merchantSettlements.agreementNumber]: "Samningsnúmer",
    [StringResources.toolTip.merchantSettlements.dbaNames]: "Nafn sölustaðar",
    [StringResources.toolTip.merchantSettlements.settlementNumber]: "Uppgjörsnúmer",

    [StringResources.toolTip.transactions.searchBy]: "Leita eftir:",
    [StringResources.toolTip.transactions.transactionId]: "Færslunúmer",
    [StringResources.toolTip.transactions.authNumber]: "Heimildarnúmer",
    [StringResources.toolTip.transactions.arn]: "Arn (tilvísunarnúmer færsluhirðis)",
    [StringResources.toolTip.transactions.terminalId]: "Númer útstöðvar",
    [StringResources.toolTip.transactions.physicalTerminalId]: "Búnaðarnúmer útstöðvar",
    [StringResources.toolTip.transactions.batchNumber]: "Bunkanúmer",
    [StringResources.toolTip.transactions.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.toolTip.transactions.agreementId]: "Samningsnúmer",
    [StringResources.toolTip.transactions.partnerId]: "Númer samstarfsaðila",
    [StringResources.toolTip.transactions.externalTransactionId]: "Utanaðkomandi færslunúmer",

    [StringResources.pages.agreements.title]: "Samningar",
    [StringResources.pages.agreements.viewSettlements]: "Skoða uppgjör",
    [StringResources.pages.agreements.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.agreements.partnerAgreementNumber]: "Samstarfssamningur",
    [StringResources.pages.agreements.partnerAgreementDescription]: "Lýsing samstarfssamnings",
    [StringResources.pages.agreements.createdTime]: "Stofndagur",
    [StringResources.pages.agreements.type]: "Tegund samnings",
    [StringResources.pages.agreements.status]: "Staða",
    [StringResources.pages.agreements.currency]: "Mynt",
    [StringResources.pages.agreements.currencyCode]: "Myntkóði",
    [StringResources.pages.agreements.agreementBalance]: "Staða samnings",
    [StringResources.pages.agreements.totalBalance]: "Heildarstaða",
    [StringResources.pages.agreements.merchantNumber]: "Söluaðilanúmer",
    [StringResources.pages.agreements.merchantName]: "Nafn söluaðila",
    [StringResources.pages.agreements.merchantRegistrationNumber]: "Kennitala",
    [StringResources.pages.agreements.isCardPresent]: "Tegund samnings",
    [StringResources.pages.agreements.description]: "Lýsing",
    [StringResources.pages.agreements.unpaidCommission]: "Ógreidd þóknun",
    [StringResources.pages.agreements.totalCommission]: "Heildar þóknun",
    [StringResources.pages.agreements.totalFixedAmount]: "Heildar föst upphæð",
    [StringResources.pages.agreements.totalPercentage]: "Heildarhlutfallsupphæð",
    [StringResources.pages.agreements.solution]: "Lausn",
    [StringResources.pages.agreements.viewSettlements]: "Skoða uppgjör",
    [StringResources.pages.agreements.cardPresent]: "POS",
    [StringResources.pages.agreements.cardNotPresent]: "Ecom",

    [StringResources.pages.merchantAgreements.title]: "Söluaðila samningar",
    [StringResources.pages.merchantAgreements.viewSettlements]: "Skoða uppgjör",
    [StringResources.pages.merchantAgreements.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.merchantAgreements.createdTime]: "Stofndagur",
    [StringResources.pages.merchantAgreements.type]: "Tegund samnings",
    [StringResources.pages.merchantAgreements.status]: "Staða",
    [StringResources.pages.merchantAgreements.currency]: "Mynt",
    [StringResources.pages.merchantAgreements.currencyCode]: "Myntkóði",
    [StringResources.pages.merchantAgreements.agreementBalance]: "Staða samnings",
    [StringResources.pages.merchantAgreements.totalBalance]: "Heildarstaða",
    [StringResources.pages.merchantAgreements.merchantNumber]: "Söluaðilanúmer",
    [StringResources.pages.merchantAgreements.merchantName]: "Nafn söluaðila",
    [StringResources.pages.merchantAgreements.merchantRegistrationNumber]: "Kennitala",
    [StringResources.pages.merchantAgreements.isCardPresent]: "Tegund samnings",
    [StringResources.pages.dashboard.title]: "Mælaborð",
    [StringResources.pages.dashboard.date]: "Dagsetning",
    [StringResources.pages.dashboard.setSalesTarget]: "Skrá sölumarkmið",
    [StringResources.pages.dashboard.targetAmount]: "Sölumarkmið",
    [StringResources.pages.dashboard.setSalesTarget]: "Skrá sölumarkmið",
    [StringResources.pages.payments.title]: "Útborganir",
    [StringResources.pages.payments.breakdown]: "Sundurliðun uppgjörs",

    [StringResources.pages.payments.breakdownNoRecords]: "Engar færslur fundust.",

    [StringResources.pages.payments.settlement]: "Uppgjör",
    [StringResources.pages.payments.netPurchase]: "Færslur alls",
    [StringResources.pages.payments.payout]: "Upphæð",
    [StringResources.pages.payments.search]: "Leita eftir Útborganir",
    [StringResources.pages.payments.dateFrom]: "Dagsetning frá",
    [StringResources.pages.payments.dateTo]: "Dagsetning til",
    [StringResources.pages.payments.amountFrom]: "Upphæð frá",
    [StringResources.pages.payments.amountTo]: "Upphæð til",
    [StringResources.pages.payments.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.payments.currency]: "Mynt",
    [StringResources.pages.payments.swift]: "Swift",
    [StringResources.pages.payments.merchantName]: "Nafn söluaðila",
    [StringResources.pages.payments.iban]: "IBAN",
    [StringResources.pages.payments.payoutAmount]: "Upphæð",
    [StringResources.pages.payments.feeAmounts]: "Upphæð gjalda",
    [StringResources.pages.payments.grossAmounts]: "Brúttó upphæð",
    [StringResources.pages.payments.paidDate]: "Dagsetning greiðslu",
    [StringResources.pages.payments.scheduled]: "Áætlaður greiðsludagur",
    [StringResources.pages.payments.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.payments.partnerName]: "Nafn samstarfsaðila",
    [StringResources.pages.payments.dbaNames]: "Nöfn sölustaða",
    [StringResources.pages.payments.ssn]: "Kennitala",
    [StringResources.pages.payments.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.payments.settlements]: "Uppgjör",
    [StringResources.pages.payments.printReportError]: "Ekki tókst að sækja skjal",
    [StringResources.pages.payments.agreement]: "Samningur",
    [StringResources.pages.payments.merchant]: "Söluaðili",
    [StringResources.pages.payments.paymentPeriod]: "Færslutímabil",
    [StringResources.pages.settlements.title]: "Uppgjör",
    [StringResources.pages.settlements.breakdown]: "Sundurliðun",
    [StringResources.pages.settlements.search]: "Leita að uppgjöri",
    [StringResources.pages.settlements.paidDateFrom]: "Greitt frá",
    [StringResources.pages.settlements.paidDateTo]: "Greitt til",
    [StringResources.pages.settlements.amountFrom]: "Upphæð frá",
    [StringResources.pages.settlements.amountTo]: "Upphæð til",
    [StringResources.pages.settlements.allAgreements]: "Allir samningar",
    [StringResources.pages.settlements.currency]: "Mynt",
    [StringResources.pages.settlements.grossPurchase]: "Heildarsala",
    [StringResources.pages.settlements.grossSettlement]: "Uppgjör (brúttó)",
    [StringResources.pages.settlements.feeAmount]: "Upphæð gjalda",
    [StringResources.pages.settlements.totalFees]: "Samtals gjöld",
    [StringResources.pages.settlements.netSettlement]: "Uppgjör (nettó)",
    [StringResources.pages.settlements.commissionAmount]: "Þóknun",
    [StringResources.pages.settlements.percentageAmount]: "Hlutfallsleg þóknun",
    [StringResources.pages.settlements.fixedAmount]: "Færslugjöld",
    [StringResources.pages.settlements.batches]: "Bunkar",
    [StringResources.pages.settlements.viewBatches]: "Skoða bunka",
    [StringResources.pages.settlements.viewTransactions]: "Skoða færslur",
    [StringResources.pages.settlements.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.settlements.gross]: "Brúttó",
    [StringResources.pages.settlements.netPurchase]: "Færslur alls",
    [StringResources.pages.settlements.date]: "Dagsetning",
    [StringResources.pages.settlements.batch]: "Bunki",

    [StringResources.pages.settlements.batchNoRecords]: "Engar skrár fundust.",

    [StringResources.pages.settlements.batchTotal]: "Upphæð",
    [StringResources.pages.settlements.batchNumber]: "Bunkanúmer",
    [StringResources.pages.settlements.status]: "Staða",
    [StringResources.pages.settlements.settlementFee]: "Uppgjörsgjald",
    [StringResources.pages.settlements.paid]: "Greitt",
    [StringResources.pages.settlements.agreementId]: "Samningsnúmer",
    [StringResources.pages.settlements.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.settlements.refundAmount]: "Endurgreiðslur",
    [StringResources.pages.settlements.createdDate]: "Stofndagur",
    [StringResources.pages.settlements.scheduledDate]: "Áætlaður greiðsludagur",
    [StringResources.pages.settlements.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.settlements.batchesCount]: "Bunkar",
    [StringResources.pages.settlements.transactionsCount]: "Færslur",
    [StringResources.pages.settlements.cumulativeFees]: "Uppsöfnuð gjöld",
    [StringResources.pages.settlements.payout]: "Útborgað",
    [StringResources.pages.settlements.partnerId]: "Númer samstarfsaðila",
    [StringResources.pages.settlements.partnerName]: "Nafn samstarfsaðila",
    [StringResources.pages.settlements.deduction]: "Frádráttur",
    [StringResources.pages.settlements.representment]: "Endursend færsla",
    [StringResources.pages.settlements.chargeback]: "Endurkrafa",
    [StringResources.pages.settlements.rollingReserve]: "Veltutengd biðgreiðsla",
    [StringResources.pages.settlements.rollingRelease]: "Biðgreiðsla til útborgunar",
    [StringResources.pages.settlements.chargebackFees]: "Endurkröfugjald",
    [StringResources.pages.settlements.swiftFees]: "Swift gjöld",
    [StringResources.pages.settlements.transactionFee]: "Færslugjald",
    [StringResources.pages.settlements.joiningFee]: "Stofngjald",
    [StringResources.pages.settlements.rejected]: "Hafnað",
    [StringResources.pages.settlements.addedSum]: "Bætt við uppgjör",
    [StringResources.pages.settlements.deductedSum]: "Dregið frá uppgjöri",
    [StringResources.pages.settlements.reversals]: "Afturkallanir",
    [StringResources.pages.settlements.preArbitration]: "Pre-arbitration",
    [StringResources.pages.settlements.posRentalFee]: "Posaleiga m. vsk",
    [StringResources.pages.settlements.exceptionCorrection]: "Lagfæring færslu",
    [StringResources.pages.settlements.dccCommission]: "DCC þóknun",
    [StringResources.pages.settlements.dbaNames]: "Nafn sölustaðar",
    [StringResources.pages.settlements.ssn]: "Kennitala",
    [StringResources.pages.settlements.claim]: "Krafa",
    [StringResources.pages.settlements.claimDueDate]: "Gjalddagi kröfu",
    [StringResources.pages.settlements.aukakronurFee]: "Aukakrónur",
    [StringResources.pages.settlements.otherFees]: "Önnur gjöld",
    [StringResources.pages.settlements.gomobileFee]: "Gomobile",
    [StringResources.pages.settlements.gomobileInitialFee]: "Gomobile stofngjald",
    [StringResources.pages.settlements.preAuthorizationFee]: "Frátekin heimild",
    [StringResources.pages.settlements.monthlyFee]: "Mánaðargjald",
    [StringResources.pages.settlements.authorizationFee]: "Heimildagjald",
    [StringResources.pages.settlements.minimumMonthlyServiceFee]: "Lágmark þjónustugjald",
    [StringResources.pages.settlements.cardNotPresentFee]: "Gjald vegna netfærslna",
    [StringResources.pages.settlements.pciFee]: "PCI mánaðargjald",
    [StringResources.pages.settlements.mobileAirTimeFee]: "Þráðlaus posi, símanotkun",
    [StringResources.pages.settlements.ecomGatewayMonthlyFee]: "Mánaðargjald greiðslugáttar",
    [StringResources.pages.settlements.ecomGatewayTransactionFee]: "Færslugjald netfærslna",
    [StringResources.pages.settlements.merchantName]: "Söluaðili",
    [StringResources.pages.settlements.print]: "Prenta",
    [StringResources.pages.settlements.printReportError]: "Ekki tókst að sækja skjal",

    [StringResources.pages.merchantSettlements.ssn]: "Kennitala",
    [StringResources.pages.merchantSettlements.createdDate]: "Stofndagur",
    [StringResources.pages.merchantSettlements.scheduledDate]: "Áætlaður greiðsludagur",
    [StringResources.pages.merchantSettlements.grossSettlement]: "Uppgjör (brúttó)",
    [StringResources.pages.merchantSettlements.netSettlement]: "Uppgjör (nettó)",
    [StringResources.pages.merchantSettlements.currency]: "Mynt",
    [StringResources.pages.merchantSettlements.dbaNames]: "Nöfn sölustaða",
    [StringResources.pages.merchantSettlements.agreementId]: "Samningsnúmer",
    [StringResources.pages.merchantSettlements.status]: "Staða",
    [StringResources.pages.merchantSettlements.paid]: "Greitt",
    [StringResources.pages.merchantSettlements.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.merchantSettlements.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.merchantSettlements.transactionsCount]: "Færslur",
    [StringResources.pages.merchantSettlements.batchesCount]: "Bunkar",
    [StringResources.pages.merchantSettlements.netPurchase]: "Færslur alls",
    [StringResources.pages.merchantSettlements.feeAmount]: "Upphæð gjalda",
    [StringResources.pages.merchantSettlements.refundAmount]: "Endurgreiðslur",
    [StringResources.pages.merchantSettlements.representment]: "Endursend færsla",
    [StringResources.pages.merchantSettlements.chargeback]: "Endurkrafa",
    [StringResources.pages.merchantSettlements.rejected]: "Hafnað",
    [StringResources.pages.merchantSettlements.reversals]: "Afturkallanir",
    [StringResources.pages.merchantSettlements.search]: "Leita að uppgjöri söluaðila",
    [StringResources.pages.merchantSettlements.allAgreements]: "Allir samningar",
    [StringResources.pages.merchantSettlements.amountFrom]: "Upphæð frá",
    [StringResources.pages.merchantSettlements.amountTo]: "Upphæð til",
    [StringResources.pages.merchantSettlements.paidDateFrom]: "Greitt frá",
    [StringResources.pages.merchantSettlements.paidDateTo]: "Greitt til",
    [StringResources.pages.merchantSettlements.createdDateFrom]: "Stofndagur frá",
    [StringResources.pages.merchantSettlements.createdDateTo]: "Stofndagur til",
    [StringResources.pages.merchantSettlements.title]: "Uppgjör söluaðila",
    [StringResources.pages.merchantSettlements.partnerCommission]: "Þóknun samstarfsaðila",

    [StringResources.pages.transactions.title]: "Færslur",
    [StringResources.pages.transactions.cardNumber]: "Kortanúmer",
    [StringResources.pages.transactions.commissionAmount]: "Þóknun",
    [StringResources.pages.transactions.percentageAmount]: "Hlutfallsleg þóknun",
    [StringResources.pages.transactions.fixedAmount]: "Færslugjöld",
    [StringResources.pages.transactions.settlementNumber]: "Uppgjörsnúmer",
    [StringResources.pages.transactions.cardType]: "Kortategund",
    [StringResources.pages.transactions.cardIssuer]: "Kortafélag",
    [StringResources.pages.transactions.settlementType]: "Tegund uppgjörs",
    [StringResources.pages.transactions.transactionId]: "Færslunúmer",
    [StringResources.pages.transactions.authorizationDate]: "Dagsetning heimildar",
    [StringResources.pages.transactions.dbaName]: "Nafn sölustaðar",
    [StringResources.pages.transactions.referenceData]: "Tilvísun",
    [StringResources.pages.transactions.purchaseDate]: "Kaupdagur",
    [StringResources.pages.transactions.originalAmount]: "Upprunaleg upphæð",
    [StringResources.pages.transactions.gross]: "Brúttó upphæð",
    [StringResources.pages.transactions.netAmount]: "Nettó upphæð",
    [StringResources.pages.transactions.currency]: "Mynt",
    [StringResources.pages.transactions.secure3D]: "3D Secure",
    [StringResources.pages.transactions.batch]: "Bunki",
    [StringResources.pages.transactions.registrationNumber]: "Kennitala",
    [StringResources.pages.transactions.reasonCode]: "Ástæðukóði",
    [StringResources.pages.transactions.contractId]: "Samningsnúmer",
    [StringResources.pages.transactions.merchantBucket]: "Gjaldaflokkur",
    [StringResources.pages.transactions.physicalTermianlId]: "Búnaðarnúmer útstöðvar",
    [StringResources.pages.transactions.lifeCycleId]: "LifeCycle númer",
    [StringResources.pages.transactions.paid]: "Greitt",
    [StringResources.pages.transactions.terminalId]: "Númer útstöðvar",
    [StringResources.pages.transactions.arn]: "Arn (tilvísunarnúmer færsluhirðis)",
    [StringResources.pages.transactions.fees]: "Gjöld",
    [StringResources.pages.transactions.originalCurrency]: "Upprunaleg mynt",
    [StringResources.pages.transactions.registrationDate]: "Skráningardagur",
    [StringResources.pages.transactions.transactionType]: "Tegund færslu",
    [StringResources.pages.transactions.merchantFee]: "Þjónustugjald",
    [StringResources.pages.transactions.authorizationCode]: "Heimildarnúmer",
    [StringResources.pages.transactions.cardEntryModeType]: "Kortainnsláttartegund",
    [StringResources.pages.transactions.authenticationMethodType]: "Auðkenningaraðferð",
    [StringResources.pages.transactions.authorizationMethodType]: "Heimildaraðferð",
    [StringResources.pages.transactions.isReversal]: "Er endurgreitt",
    [StringResources.pages.transactions.search]: "Leita",
    [StringResources.pages.transactions.agreementNumber]: "Samningsnúmer",
    [StringResources.pages.transactions.amountFrom]: "Upphæð frá",
    [StringResources.pages.transactions.amountTo]: "Upphæð til",
    [StringResources.pages.transactions.purchaseDateFrom]: "Kaupdagur frá",
    [StringResources.pages.transactions.purchaseDateTo]: "Kaupdagur til",
    [StringResources.pages.transactions.registrationDateFrom]: "Skráningardagur frá",
    [StringResources.pages.transactions.registrationDateTo]: "Skráningardagur til",
    [StringResources.pages.transactions.paidDateFrom]: "Greitt frá",
    [StringResources.pages.transactions.paidDateTo]: "Greitt til",
    [StringResources.pages.transactions.partnerContractId]: "Samningur samstarfsaðila",
    [StringResources.pages.transactions.partnerSettlementPaymentId]: "Útborgunarnúmer",

    [StringResources.pages.employees.title]: "Notendur",
    [StringResources.pages.employees.permissions]: "Aðgangsheimildir",
    [StringResources.pages.employees.apiKey]: "API lykill",
    [StringResources.pages.employees.table.usernameHeaderLabel]: "Tölvupóstur",
    [StringResources.pages.employees.table.statusHeaderLabel]: "Staða",
    [StringResources.pages.employees.table.statusActiveLabel]: "Virkja",
    [StringResources.pages.employees.table.statusInactiveLabel]: "Óvirkja",
    [StringResources.pages.employees.table.parnterUserRoleHeaderLabel]: "Týpa",
    [StringResources.pages.employees.create.title]: "Bæta við notanda",
    [StringResources.pages.employees.create.permissions]: "Aðgangsheimildir",
    [StringResources.pages.employees.create.permissionsPage]: "Síða",
    [StringResources.pages.employees.create.confirm]: "Bæta við notanda",
    [StringResources.pages.employees.create.buttonText]: "Bæta við notanda",
    [StringResources.pages.employees.create.close]: "Loka",
    [StringResources.pages.employees.create.formEmailInputLabel]: "Notandanafn",
    [StringResources.pages.employees.create.formContractInputLabel]: "Samningar",
    [StringResources.pages.employees.create.formButtonCaption]: "Búa til",
    [StringResources.pages.employees.create.formEmailValidationMsg]: "Sláðu inn virkt netfang",
    [StringResources.pages.employees.create.formUsernameAlreadyHasAccessValidationMsg]:
        "Notandi hefur nú þegar aðgang að þessum söluaðilasamningi",
    [StringResources.pages.employees.create.successMsg]: "Nýjum notanda hefur verið bætt við",

    [StringResources.pages.employees.edit.title]: "Breyta notanda",
    [StringResources.pages.employees.edit.permissions]: "Aðgangsheimildir",
    [StringResources.pages.employees.edit.permissionsPage]: "Síða",
    [StringResources.pages.employees.edit.confirm]: "Breyta",
    [StringResources.pages.employees.edit.deactivate]: "Óvirkja",
    [StringResources.pages.employees.edit.close]: "Loka",
    [StringResources.pages.employees.edit.formContractInputLabel]: "Samningar",
    [StringResources.pages.employees.edit.successMsg]: "Tókst að breyta notanda",
    [StringResources.pages.employees.edit.errorMsg]: "Villa við að breyta notanda",
    [StringResources.pages.employees.edit.errorApiKeyMsg]: "Villa við að sækja API lykil",
    [StringResources.pages.employees.edit.successDeactivateMsg]: "Tókst að slökkva á notanda",

    [StringResources.pages.employees.details.employeeId]: "Samningsnúmer",
    [StringResources.pages.employees.details.username]: "Notandanafn",
    [StringResources.pages.employees.details.status]: "Staða",
    [StringResources.pages.employees.details.role]: "Aðgangur",

    [StringResources.pages.notFound.title]: "Síða finnst ekki",
    [StringResources.pages.notFound.description]:
        "Síðan sem þú ert að reyna að skoða er ekki til eða þú hefur ekki leyfi til að skoða hana.",
    [StringResources.pages.notFound.link]: "Til baka",
    [StringResources.pages.none.title]: "Ekkert",
    [StringResources.pages.settings.title]: "Stillingar",
    [StringResources.pages.settings.generalTab]: "Almennt",
    [StringResources.pages.settings.emailsAndNotificationsTab]: "Tilkynningar",
    [StringResources.pages.settings.changePasswordTab]: "Breyta lykilorði",
    [StringResources.pages.settings.accountAndSecurity.title]: "Aðgangs- og öryggisstillingar",
    [StringResources.pages.settings.accountAndSecurity.details]: "Upplýsingar",
    [StringResources.pages.settings.accountAndSecurity.changePasswordTitle]: "Breyta lykilorði",
    [StringResources.pages.settings.accountAndSecurity.username]: "Skráður inn sem:",
    [StringResources.pages.settings.general.defaultLanguage]: "Tungumál",
    [StringResources.pages.settings.changePassword.oldPassword]: "Eldra lykilorð",
    [StringResources.pages.settings.changePassword.newPassword]: "Nýtt lykilorð",
    [StringResources.pages.settings.changePassword.repeatNewPassword]: "Endurtaka nýtt lykilorð",
    [StringResources.pages.settings.changePassword.saveNewPassword]: "Vista nýtt lykilorð",
    [StringResources.pages.settings.changePassword.confirmNewPassword]: "Staðfestu nýtt lykilorð",
    [StringResources.pages.settings.changePassword.currentPassword]: "Núverandi lykilorð",
    [StringResources.pages.settings.emailsAndNotifications.statementsTitle]: "Yfirlit",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioTitle]:
        "Tilkynning er send með tölvupósti þegar nýtt yfirlit er gefið út. Þú færð einungis yfirlit fyrir þá samninga sem þú hefur aðgang að í gegnum þjónustuvef Straums.",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioPdfEmail]:
        "Tölvupóstur með yfirliti í viðhengi (pdf)",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNotificationEmail]:
        "Tölvupóstur án viðhengis",
    [StringResources.pages.settings.emailsAndNotifications.statementsRadioNoEmail]:
        "Ég vil ekki fá tilkynningu með tölvupósti",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioSuccess]:
        "Tilkynningar stillingar voru uppfærðar",
    [StringResources.pages.settings.emailsAndNotifications.radioChange.statementsRadioError]:
        "Villa við að uppfæra tilkynningar stillingar",

    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].title]: "Samstarfssamningur",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].details]: "Upplýsingar",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].unpaidCommission]: "Ógreidd þóknun",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].description]: "Lýsing",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].partnerContractStatus]: "Samstarfssamningsstaða",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].partnerContractNumber]: "Samstarfssamningsnúmer",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].settlementCurrency]: "Uppgjörsmynt",
    [StringResources.pages[PageTypeEnum.PartnerAgreementDetails].detailsAndUnpaidCommission]:
        "Upplýsingar, Ógreidd þóknun",

    [StringResources.export.confirm]: "Sækja gögn",
    [StringResources.export.title]: "Sækja gögn",
    [StringResources.export.close]: "Hætta við",
    [StringResources.export.exportType]: "Útflutningstegund",
    [StringResources.export.exportTypeCSV]: "CSV",
    [StringResources.export.exportTypeXLSX]: "XLSX",
    [StringResources.export.exportColumn]: "Veldu Gögn",
    [StringResources.export.exportAllColumns]: "Allir dálkar",
    [StringResources.export.exportVisibleColumns]: "Einungis sýnilegir dálkar",
    [StringResources.export.exportSuccess]: "Niðurhal tókst",
    [StringResources.export.exportError]: "Niðurhal mistókst",
    [StringResources.export.exportInfo]: "Í vinnslu",
    [StringResources.export.exportToManyRows]:
        "Gögn innihalda yfir {{maxItems}} línur. Vinsamlegast breytið stillingum í Síu og reynið aftur.",
    [StringResources.input.passwordShowCaption]: "Sýna",
    [StringResources.input.passwordHideCaption]: "Fela",
    [StringResources.input.passwordStrengthBarLabel]: "Styrkur lykilorðs",
    [StringResources.input.passwordStrengthBad]: "Mjög veikt",
    [StringResources.input.passwordStrengthWeak]: "Veikt",
    [StringResources.input.passwordStrengthPassable]: "Miðlungs",
    [StringResources.input.passwordStrengthGood]: "Sterkt",
    [StringResources.input.passwordStrengthVeryGood]: "Mjög sterkt",
    [StringResources.login.backActionLabel]: "Fara til baka",
    [StringResources.login.title]: "Samstarfsvefur",
    [StringResources.login.formTitle]: "Innskráning",
    [StringResources.login.formImpersonateTitle]: "Innskráning (Admin Impersonate Login)",
    [StringResources.login.formEmailInputLabel]: "Tölvupóstur",
    [StringResources.login.formEmailValidationMsg]: "Vinsamlegast sláðu inn virkt netfang",
    [StringResources.login.formPasswordInputLabel]: "Lykilorð",
    [StringResources.login.formButtonCaption]: "Skrá inn",
    [StringResources.login.forgotPasswordLink]: "Búin/nn að gleyma lykilorðinu?",
    [StringResources.login.loginFailed]: "Þú hefur slegið inn rangt notandanafn eða lykilorð",
    [StringResources.forgotPassword.title]: "Endursetja lykilorð?",
    [StringResources.forgotPassword.description]:
        "Vinsamlegast skráðu netfangið sem þú ert með skráð fyrir þjónustuvef Straums. Þú munt fá sendan tölvupóst með link til að stilla nýtt lykilorð.",
    [StringResources.forgotPassword.formEmailInputLabel]: "Netfang",
    [StringResources.forgotPassword.formEmailValidationMsg]: "Vinsamlegast sláðu inn virkt netfang",
    [StringResources.forgotPassword.formButtonCaption]: "Endursetja lykilorð",
    [StringResources.forgotPassword.backButtonCaption]: "Hætta við",
    [StringResources.forgotPassword.formSubmittedTitle]: "Tölvupóstur hefur verið sendur",
    [StringResources.forgotPassword.formSubmittedDescription]:
        "Við höfum sent þér tölvupóst á <strong>{{email}}</strong> sem inniheldur link sem þú þarft að ýta á til þess að geta útbúa nýtt lykilorð.",
    [StringResources.forgotPassword.formSubmittedBackButtonCaption]: "Fara til baka í innskráningu",
    [StringResources.resetPassword.title]: "Velja nýtt lykilorð",
    [StringResources.resetPassword.formPasswordInputLabel]: "Nýtt lykilorð",
    [StringResources.resetPassword.formPasswordValidationMsg]: "Lykilorðið verður að vera að lágmarki 8 stafir",
    [StringResources.resetPassword.formConfirmPasswordInputLabel]: "Staðfesta nýtt lykilorð",
    [StringResources.resetPassword.formConfirmPasswordValidationMsg]: "Lykilorð verður að vera það sama",
    [StringResources.resetPassword.formButtonCaption]: "Staðfesta",
    [StringResources.resetPassword.formSubmittedTitle]: "Lykilorði þínu hefur nú verið breytt",
    [StringResources.resetPassword.formSubmittedDescription]: "Núna getur þú skráð þig inn með nýja lykilorðinu.",
    [StringResources.resetPassword.formSubmittedBackButtonCaption]: "Fara til baka í innskráningu",
    [StringResources.confirmAccount.title]: "Stofna aðgang að þjónustuvef Straums",
    [StringResources.confirmAccount.subTitle]: "Veldur lykilorð til að klára að setja upp aðganginn þinn.",
    [StringResources.confirmAccount.formDisplayUsernameInputLabel]: "Netfang:",
    [StringResources.confirmAccount.formPasswordInputLabel]: "Veldu lykilorð",
    [StringResources.confirmAccount.formPasswordValidationMsg]: "Lykilorðið verður að vera að lágmarki 8 stafir",
    [StringResources.confirmAccount.formConfirmPasswordInputLabel]: "Staðfesta lykilorð",
    [StringResources.confirmAccount.formConfirmPasswordValidationMsg]: "Lykilorðin verða að vera það sama",
    [StringResources.confirmAccount.formButtonCaption]: "Áfram",
    [StringResources.confirmAccount.formSubmittedTitle]: "Lykilorði þínu hefur verið breytt",
    [StringResources.confirmAccount.formSubmittedDescription]: "Þú getur nú skráð þig inn.",
    [StringResources.confirmAccount.formSubmittedBackButtonCaption]: "Fara til baka í innskráningu",
    [StringResources.confirmAccount.contactServiceDesk]:
        "Ert þú að lenda í vandræðum með að búa til aðgang? Þú getur haft samband með því að senda okkur tölvupóst á <hyperLink>{{straumurEmail}}</hyperLink> eða hringt í okkur í síma <telLink>{{straumurPhone}}</telLink>.",
    [StringResources.multiselect.selected]: "Valið",
    [StringResources.multiselect.options]: "Valmöguleikar",
    [StringResources.multiselect.selectAll]: "Velja allt",
    [StringResources.multiselect.clearAll]: "Hreinsa síu",
    [StringResources.dashboard.totalSales]: "Velta mánaðarins",
    [StringResources.dashboard.totalSalesTooltip]: "Veltan fyrir valinn mánuð",
    [StringResources.dashboard.unpaidCommissionAmount]: "Ógreidd þóknun",
    [StringResources.dashboard.partnerContract]: "Samningur samstarfsaðila",
    [StringResources.dashboard.allPartnerContracts]: "Allir samstarfssamningar",
    [StringResources.dashboard.unpaidCommissionAmountToolTip]: "Uppsöfnuð þóknun fyrir valinn mánuð",
    [StringResources.dashboard.numberOfSales]: "Færslur",
    [StringResources.dashboard.averageNumberOfSales]: "Meðalfjöldi færslna",
    [StringResources.dashboard.grossSales]: "Velta",
    [StringResources.dashboard.averageGrossSales]: "Meðalsöluvelta",
    [StringResources.dashboard.day]: "Dagur",
    [StringResources.dashboard.noData]: "Engin gögn til að sýna",
    [StringResources.dashboard.exportGraph]: "Hlaða niður gögnum",
    [StringResources.dashboard.january]: "Janúar",
    [StringResources.dashboard.february]: "Febrúar",
    [StringResources.dashboard.march]: "Mars",
    [StringResources.dashboard.april]: "Apríl",
    [StringResources.dashboard.may]: "Maí",
    [StringResources.dashboard.june]: "Júní",
    [StringResources.dashboard.july]: "Júlí",
    [StringResources.dashboard.august]: "Ágúst",
    [StringResources.dashboard.september]: "September",
    [StringResources.dashboard.october]: "Október",
    [StringResources.dashboard.november]: "Nóvember",
    [StringResources.dashboard.december]: "Desember",
    [StringResources.dashboard.salesTileTooltip]: "Dagleg velta og fjöldi færslna fyrir valinn mánuð",
    [StringResources.dashboard.salesTileTitle]: "Velta",
    [StringResources.dashboard.turnOverTileTooltip]: "Þróun helstu talnagilda fyrir síðustu 12 mánuði",
    [StringResources.dashboard.turnOverTileTitle]: "Þróun",
    [StringResources.dashboard.comission]: "Þóknun",
    [StringResources.dashboard.averageComission]: "Meðalfjöldi þóknun",
    [StringResources.dashboard.month]: "Mánuð",

    [StringResources.versionCheck.text]:
        "Ný útgáfa af vefnum var að detta í hús með allskonar nýjungum - til að uppfæra þarf einungis að smella ",
    [StringResources.versionCheck.button]: "hérna!",

    [StringResources.activityTracker.somethingWentWrong]: "Eitthvað fór úrskeiðis. Vinsamlegast skráðu þig aftur inn",
    [StringResources.activityTracker.loggedOutInactivtiy]:
        "Þú hefur verið skráð/ur út af vefnum vegna aðgerðaleysis. Vinsamlegast skráðu þig aftur inn.",

    [StringResources.reports[ReportType.Agreements].title]: "Samningar",
    [StringResources.reports[ReportType.Payments].title]: "Greiðslur",
    [StringResources.reports[ReportType.Settlements].title]: "Uppgjör",
    [StringResources.reports[ReportType.Transactions].title]: "Færslur",
    [StringResources.reports[ReportType.Employees].title]: "Notendur",
    [StringResources.reports[ReportType.Dashboard].title]: "Mælaborð",
    [StringResources.reports[ReportType.NotFound].title]: "Síða finnst ekki",
    [StringResources.reports[ReportType.MerchantAgreements].title]: "Söluaðila samningar",
    [StringResources.reports[ReportType.MerchantSettlements].title]: "Uppgjör söluaðila",
    [StringResources.reports[ReportType.None].title]: "Ekkert",
    [StringResources.reports[ReportType.SettlementDefinitions].title]: "Uppgjörsstillingar",
    [StringResources.reports[ReportType.SettlementDefinitions].bankAccountNumber]: "Bankareikningsnúmer",
    [StringResources.reports[ReportType.SettlementDefinitions].validFrom]: "Gildir frá",
    [StringResources.reports[ReportType.SettlementDefinitions].validTo]: "Gildir til",
    [StringResources.reports[ReportType.SettlementDefinitions].settlementCycleStartsOn]: "Uppgjörstímabil byrjar á",
    [StringResources.reports[ReportType.SettlementDefinitions].dayOfMonth]: "Mánaðardagur",
    [StringResources.reports[ReportType.SettlementDefinitions].iban]: "Alþjóðlegt bankareikningsnúmer (IBAN)",
    [StringResources.reports[ReportType.SettlementDefinitions].externalId]:
        "Utankerfis bankareikningsnúmer (External ID)",
    [StringResources.reports[ReportType.SettlementDefinitions].currentDefinitionFilter]:
        "Núgildandi uppgjörsstillingar",
    [StringResources.reports[ReportType.SettlementDefinitions].pendingDefinitionFilter]: "Bíða uppgjörsstillingar",
    [StringResources.reports[ReportType.SettlementDefinitions].updatePending]: "Uppfærsla í bið ({{date}})",

    [StringResources.reports[ReportType.CommissionDefinitions].title]: "Þóknunarsamningar",
    [StringResources.reports[ReportType.CommissionDefinitions].validFrom]: "Gildir frá",
    [StringResources.reports[ReportType.CommissionDefinitions].validTo]: "Gildir til",
    [StringResources.reports[ReportType.CommissionDefinitions].percentage]: "Prósenta",
    [StringResources.reports[ReportType.CommissionDefinitions].transactionFee]: "Færslugjald",
    [StringResources.reports[ReportType.CommissionDefinitions].currencyId]: "Mynt",
    [StringResources.reports[ReportType.CommissionDefinitions].feeTransactionCategoryType]: "Greiðsluflokkstegund",
    [StringResources.reports[ReportType.CommissionDefinitions].feeBrandType]: "Kortafélag",
    [StringResources.reports[ReportType.CommissionDefinitions].feeCardUsageType]: "Kortanotkun",
    [StringResources.reports[ReportType.CommissionDefinitions].feeCardCategoryType]: "Kortaflokkstegund",
    [StringResources.reports[ReportType.CommissionDefinitions].feeTransactionRegionType]: "Greiðslusvæði",
    [StringResources.reports[ReportType.CommissionDefinitions].all]: "Allar",
    [StringResources.reports[ReportType.CommissionDefinitions].updatePending]: "Uppfærsla í bið",
    [StringResources.reports[ReportType.CommissionDefinitions].currentDefinitionFilter]: "Núverandi þóknunarstillingar",
    [StringResources.reports[ReportType.CommissionDefinitions].pendingDefinitionFilter]: "Bíddu þóknunarstillingar",

    [StringResources.reports[ReportType.LinkedMerchantAgreements].title]: "Tengdir söluaðilasamningar",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].contractNumber]: "Samningsnúmer",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].contractStatus]: "Staða",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].isCardPresent]: "Samningstegund",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].currencyId]: "Mynt",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].createdDate]: "Stofnað",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].dbaNames]: "Sölustaðanöfn",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].solution]: "Lausn",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].feeCalculationTypes]: "Útreikningstegund",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].description]: "Lýsing",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].mccCodes]: "MCC",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].linkedTime]: "Tengitími",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].lastCreatedSettlementDate]:
        "Síðasta uppgjörsdagsetning",
    [StringResources.reports[ReportType.LinkedMerchantAgreements].searchString]: "Leita",

    [StringResources.networkStatusIndicator.online]: "Tenging virk!",
    [StringResources.networkStatusIndicator.offline]:
        "Nettenging þín er ekki virk. Vinsamlegast athugað nettenginguna þína.",
};
