import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dashboardDataGet, dashboardSetSalesTarget, turnoverTrendData } from "redux/actions/dashboardActions";
import { GetDashboardDataResponse, GetDashboardTurnoverTrendResponse, IDashboard } from "redux/models/dashboardModels";
import { PayloadData } from "redux/models/userModels";

const initialState: IDashboard = {
    dashboardData: {
        isLoading: false,
        monthlyTarget: 0,
        totalSales: 0,
        totalSalesToday: 0,
        upcomingPayment: 0,
        upcomingPaymentDate: "",
        salesPerDay: [],
        unpaidCommission: 0,
    },
    turnoverTrendDataLoading: false,
};

export const dashboardReducer = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(dashboardDataGet.pending, state => {
            state.dashboardData.isLoading = true;
        });
        builder.addCase(dashboardDataGet.fulfilled, (state, action: PayloadAction<GetDashboardDataResponse>) => {
            state.dashboardData = action.payload;
            state.dashboardData.isLoading = false;
        });
        builder.addCase(dashboardDataGet.rejected, state => {
            state.dashboardData.isLoading = false;
        });

        builder.addCase(dashboardSetSalesTarget.pending, state => {});
        builder.addCase(dashboardSetSalesTarget.fulfilled, state => {});
        builder.addCase(dashboardSetSalesTarget.rejected, state => {});

        builder.addCase(turnoverTrendData.pending, state => {
            state.turnoverTrendDataLoading = true;
        });
        builder.addCase(
            turnoverTrendData.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardTurnoverTrendResponse>>) => {
                state.turnoverTrendData = action.payload.data.turnoverTrend;
                state.turnoverTrendDataLoading = false;
            }
        );
        builder.addCase(turnoverTrendData.rejected, state => {
            state.turnoverTrendDataLoading = false;
        });
    },
});

const { reducer } = dashboardReducer;
export default reducer;
