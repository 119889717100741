import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { employeeCreateEmployee } from "redux/actions/employeeActions";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { useAppDispatch } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import "./createEmployeeModal.scss";

interface ICreateEmployeeForm {
    username: string;
}

const CreateEmployeeModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState("");

    const errorMessageRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (errorMessage) {
            errorMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [errorMessage]);

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            username: "",
        },
        validationSchema: Yup.object({
            username: Yup.string().email(t(StringResources.pages.employees.create.formEmailValidationMsg)).required(),
        }),
        onSubmit: async (value: ICreateEmployeeForm) => {
            setErrorMessage("");

            const model = {
                username: value.username,
            };

            try {
                await dispatch(employeeCreateEmployee(model)).unwrap();
                dispatch(confirmModal({ modalType: ModalTypeEnum.CreateEmployee }));
                toast.success(t(StringResources.pages.employees.create.successMsg).toString());
            } catch (error: any) {
                setErrorMessage(error?.message);
            }
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreateEmployee }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.pages.employees.create.title)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="create-employee__modal-body">
                    <Form.Group className="create-employee__form-row">
                        <BaseInput
                            type={"email"}
                            name={"username"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.employees.create.formEmailInputLabel)}`}
                            value={formValidation.values["username"]}
                            invalid={formValidation.touched["username"] && formValidation.errors["username"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    {errorMessage && (
                        <p ref={errorMessageRef} className="create-employee__form-error">
                            {errorMessage}
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.close)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={`${t(StringResources.pages.employees.create.confirm)}`}
                        styleType="solid"
                        disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateEmployeeModal;
