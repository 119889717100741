import "hammerjs";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PATHS } from "utils/routing/paths";
import { PortalRouteElements, PortalWrapperLazy, globalElements } from "utils/routing/pathsAndElements";
import { FallbackComponent } from "./fallbackComponent";
import GlobalRoutesWrapper from "./wrappers/globalRoutesWrapper";

const SettingsLazy = React.lazy(() => import("../../pages/settings/settings"));
const NotFoundLazy = React.lazy(() => import("../../pages/notFound/notFound"));
const PartnerAgreementDetailsLazy = React.lazy(
    () => import("../../pages/partnerAgreementDetails/partnerAgreementDetails")
);

const MainLayout = () => {
    return (
        <Suspense fallback={<FallbackComponent />}>
            <Routes>
                {globalElements.map(element => {
                    return (
                        <Route
                            key={element.path}
                            path={element.path}
                            element={
                                <GlobalRoutesWrapper>
                                    <element.element />
                                </GlobalRoutesWrapper>
                            }
                        />
                    );
                })}
                <Route path={PATHS.Portal.Index} element={<PortalWrapperLazy />}>
                    {PortalRouteElements.map(element => {
                        return <Route key={element.path} path={element.path} element={<element.element />} />;
                    })}

                    <Route path={PATHS.Portal.PartnerAgreementDetails} element={<PartnerAgreementDetailsLazy />} />
                    <Route path={PATHS.Portal.Settings} element={<SettingsLazy />} />
                    <Route path={"*"} element={<NotFoundLazy />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default MainLayout;
