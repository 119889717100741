import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPartnerAgreementDetailsData } from "redux/models/agreementModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const getPartnerAgreementDetailsThunk = createAsyncThunk<IPartnerAgreementDetailsData, string | undefined>(
    "agreement/getPartnerAgreementDetails",
    async partnerContractId => {
        const response = await executeAxiosRequestWithRefresh<IPartnerAgreementDetailsData>({
            url: `/api/contract/get-contract-details`,
            method: "GET",
            params: {
                partnerContractId: partnerContractId,
            },
        });

        return response.data!;
    }
);
