import { FC, ReactNode } from "react";
import Select, { FormatOptionLabelMeta, OnChangeValue, StylesConfig, components } from "react-select";
import "./baseSelect.scss";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { AngleIcon } from "components/icons";
import variables from "../../style/colors.module.scss";
import classNames from "classnames";

export interface GroupedOptions {
    label: string;
    options: Array<Option>;
}

export interface Option {
    value?: number | string;
    label: string;
    descriptionLabel?: string | React.ReactNode;
    selected: boolean;
    extraData?: any;
}

interface IBaseSelectProps extends IBaseSimpleSelectProps {
    label?: React.ReactNode;
    descriptionLabel?: React.ReactNode;
    inputWrapper?: boolean;
}

interface IBaseSimpleSelectProps {
    options: Array<Option> | Array<GroupedOptions>;
    isDisabled?: boolean;
    isClearable?: boolean;
    onChange: (selectedItem: Option) => void;
    size?: "sm" | "md";
    placeholder?: string;
    handleFormatOptionLabel?: (
        data: Option,
        formatOptionLabelMeta: FormatOptionLabelMeta<Option>
    ) => ReactNode | undefined;
}

const BaseSelect: FC<IBaseSelectProps> = ({ label, inputWrapper = true, options, isDisabled, ...props }) => {
    if (inputWrapper) {
        let selectedValue: Option | undefined;
        if (options.length > 0) {
            if ((options[0] as GroupedOptions).options) {
                selectedValue = (options as Array<GroupedOptions>)
                    .map(x => x.options)
                    .flat()
                    .find(x => x.selected);
            } else {
                selectedValue = (options as Array<Option>).find(x => x.selected);
            }
        }

        return (
            <BaseInputWrapper label={label} value={selectedValue?.label} disabled={isDisabled}>
                <div
                    className={classNames("select-wrapper", {
                        "select-wrapper--sm": props.size === "sm",
                    })}
                >
                    <BaseSimpleSelect {...props} isDisabled={isDisabled} options={options} />
                </div>
            </BaseInputWrapper>
        );
    }

    return <BaseSimpleSelect {...props} options={options} />;
};

const BaseSimpleSelect: FC<IBaseSimpleSelectProps> = ({
    isClearable,
    isDisabled,
    options,
    onChange,
    size = "md",
    placeholder = "",
    handleFormatOptionLabel,
}) => {
    const handleChange = (selectedItem: OnChangeValue<Option, boolean>) => {
        // we are casting to Option because we will always use this component as SingleSelect so it will return selected option
        onChange(selectedItem as Option);
    };

    let selectedValue: Option | undefined;
    if (options.length > 0) {
        if ((options[0] as GroupedOptions).options) {
            selectedValue = (options as Array<GroupedOptions>)
                .map(x => x.options)
                .flat()
                .find(x => x.selected);
        } else {
            selectedValue = (options as Array<Option>).find(x => x.selected);
        }
    }

    // programatically style for react-select component
    const customStyles: StylesConfig<Option, boolean> = {
        container: (styles, state) => {
            return {
                ...styles,
                border: `1px solid ${variables.colorCosmosBlueGamma}`,
                paddingTop: size === "sm" ? "0px" : "4px",
                paddingBottom: size === "sm" ? "0px" : "4px",
                borderRadius: "8px",
                backgroundColor: state.isDisabled ? variables.colorGrayEpsilon : variables.colorTransparent,
                ":hover": {
                    ...styles[":hover"],
                    borderColor: variables.colorCosmosBlueDelta,
                    backgroundColor: variables.colorCosmosBlueAlpha,
                },
            };
        },
        multiValue: (styles, state) => {
            return {
                ...styles,
                fontWeight: 500,
                color: variables.colorPrimaryAlpha,
            };
        },
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: variables.colorTransparent,
                border: "0",
                boxShadow: "none",
                height: "100%",
                cursor: "pointer",
            };
        },
        option: (styles, state) => {
            return {
                ...styles,
                display: "flex",
                cursor: state.isDisabled ? "not-allowed" : "default",
                marginTop: "4px",
                marginBottom: "4px",
                padding: "12px 16px",
                fontSize: "1rem",
                lineHeight: 1.5,
                borderRadius: "4px",
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                    ? variables.colorBlueGamma
                    : state.isFocused
                    ? variables.colorGrayBeta
                    : undefined,
                color: state.isDisabled ? undefined : state.isSelected ? variables.colorSecondaryDelta : undefined,
                ":active": {
                    ...styles[":active"],
                    backgroundColor: variables.colorGrayBeta,
                },
            };
        },
        menuList: styles => ({
            ...styles,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: "4px",
            marginRight: "4px",
        }),
        menu: styles => ({ ...styles, zIndex: 500, minWidth: "200px" }),
        menuPortal: styles => ({ ...styles, zIndex: 500 }),
        clearIndicator: styles => ({
            ...styles,
            paddingRight: "16px",
            color: variables.colorPrimaryAlpha,
            ":hover": {
                ...styles[":hover"],
                color: variables.colorPrimaryAlpha,
                cursor: "pointer",
            },
        }),
    };

    return (
        <Select
            styles={customStyles}
            className="form-control"
            closeMenuOnSelect={true}
            isClearable={isClearable}
            isDisabled={isDisabled}
            isMulti={false}
            placeholder={placeholder}
            options={options}
            formatOptionLabel={handleFormatOptionLabel}
            value={selectedValue ?? null}
            onChange={handleChange}
            menuPlacement="auto"
            menuPosition={"fixed"}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                    <AngleIcon
                        height={14}
                        width={10}
                        className={classNames("select-wrapper__toggle-icon", {
                            "select-wrapper__toggle-icon--disabled": isDisabled,
                        })}
                    />
                ),
                ClearIndicator: (props: any) => (
                    <div
                        onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onMouseDown={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <components.ClearIndicator {...props} />
                    </div>
                ),

                SingleValue: (props: any) => (
                    <components.SingleValue {...props}>
                        {props.data.descriptionLabel ? props.data.descriptionLabel : props.data.label}
                    </components.SingleValue>
                ),
            }}
        />
    );
};

export default BaseSelect;
