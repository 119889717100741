import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPageSettingsRequest } from "utils/models";
import { setPageSettings, setPageSize } from "utils/storageActions";

export const setColumnVisibilityThunk = createAsyncThunk<any, IPageSettingsRequest>(
    "report/setColumnVisibility",
    request => {
        setPageSettings(request.pageType, request.reportType, request.columns);

        return request;
    }
);

export const setQueryPageSizeThunk = createAsyncThunk<any, number>("report/setQueryPageSize", (request, thunkApi) => {
    setPageSize(request);
    return request;
});
