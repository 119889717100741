import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseEmployeesRoute } from "pages/employees/api/employeeApi";
import { ICreateEmployeeRequest } from "redux/models/employeeModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const employeeCreateEmployee = createAsyncThunk<any, ICreateEmployeeRequest>(
    "employee/createEmployee",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseEmployeesRoute}/create-user`,
            method: "POST",
            data: request,
        });

        return response.data;
    }
);
