export enum PageTypeEnum {
    None = "none",
    Agreements = "agreements",
    Payments = "payments",
    Settlements = "settlements",
    Transactions = "transactions",
    Employees = "employees",
    Dashboard = "dashboard",
    NotFound = "notFound",
    MerchantAgreements = "merchantAgreements",
    MerchantSettlements = "merchantSettlements",

    // Details
    PartnerAgreementDetails = "partnerAgreementDetails",
}

export enum ReportType {
    // Reports
    None = "none",
    Agreements = "agreements",
    Payments = "payments",
    Settlements = "settlements",
    Transactions = "transactions",
    Employees = "employees",
    Dashboard = "dashboard",
    NotFound = "notFound",
    MerchantAgreements = "merchantAgreements",
    MerchantSettlements = "merchantSettlements",

    // Agreement Details
    SettlementDefinitions = "settlementDefinitions",
    LinkedMerchantAgreements = "linkedMerchantAgreements",
    CommissionDefinitions = "commissionDefinitions",
}
