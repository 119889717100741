import { createAsyncThunk } from "@reduxjs/toolkit";
import { IFilterTypesData, IPartnerFilterTypesResponse } from "redux/models/globalModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const getFilterTypesThunk = createAsyncThunk<IFilterTypesData>("report/getFilterTypes", async request => {
    const response = await executeAxiosRequestWithRefresh({
        url: "/api/filterTypes",
        method: "GET",
    });
    return response.data;
});

export const userGetMerchants = createAsyncThunk("user/getMerchants", async () => {
    return await executeAxiosRequestWithRefresh({
        url: "/api/merchants/available-merchants",
        method: "GET",
    });
});

export const userGetPartnerContracts = createAsyncThunk("user/getPartnerContracts", async () => {
    const response = await executeAxiosRequestWithRefresh({
        url: "/api/partner/get-available-partner-contracts",
        method: "GET",
    });

    return response.data;
});

export const userGetPartnerSettings = createAsyncThunk("user/getPartnerSettings", async () => {
    return await executeAxiosRequestWithRefresh({
        url: "/api/partner/get-partner-settings",
        method: "GET",
    });
});

export const getPartnerFilterTypesThunk = createAsyncThunk<IPartnerFilterTypesResponse>(
    "partners/getPartnerFilterTypes",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/filterTypes/partner-filter-types",
            method: "GET",
        });

        return response.data;
    }
);
