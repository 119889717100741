import { createSlice } from "@reduxjs/toolkit";
import { getPartnerAgreementDetailsThunk } from "redux/actions/agreementActions";
import { IAgreementState } from "redux/models/agreementModels";

const initialState: IAgreementState = {
    partnerAgreementDetails: undefined,
};

export const agreementReducer = createSlice({
    name: "agreement",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPartnerAgreementDetailsThunk.fulfilled, (state, action) => {
            state.partnerAgreementDetails = action.payload;
        });
        builder.addCase(getPartnerAgreementDetailsThunk.pending, (state, action) => {
            state.partnerAgreementDetails = undefined;
        });
    },
});

const { reducer } = agreementReducer;
export default reducer;
