import React from "react";
import { StringResources } from "utils/language/languageResource";
import { PATHS } from "./paths";
const DashboardLazy = React.lazy(() => import("../../pages/dashboard/dashboard"));
const PaymentsLazy = React.lazy(() => import("../../pages/payments/payments"));
const AgreementsLazy = React.lazy(() => import("../../pages/agreements/agreements"));
const MerchantAgreementsLazy = React.lazy(() => import("../../pages/merchantAgreements/merchantAgreements"));
const SettlementsLazy = React.lazy(() => import("../../pages/settlements/settlements"));
const MerchantSettlementsLazy = React.lazy(() => import("../../pages/merchantSettlements/merchantSettlements"));
const TransactionsLazy = React.lazy(() => import("../../pages/transactions/transactions"));
const EmployeesLazy = React.lazy(() => import("../../pages/employees/employees"));
const LoginLazy = React.lazy(() => import("../../pages/login/login"));
const ForgotPasswordLazy = React.lazy(() => import("../../pages/forgotPassword/forgotPassword"));
const ResetPasswordLazy = React.lazy(() => import("../../pages/resetPassword/resetPassword"));
const ConfirmAccountLazy = React.lazy(() => import("../../pages/confirmAccount/confirmAccount"));
const NotFoundLazy = React.lazy(() => import("../../pages/notFound/notFound"));

import {
    AgreementsIcon,
    DashboardIcon,
    PaymentsIcon,
    SettlementsIcon,
    TransactionsIcon,
    UsersIcon,
} from "components/icons";
import { IconSVGProps } from "components/icons/types";

export const globalElements = [
    {
        path: PATHS.Global.Login,
        element: LoginLazy,
    },
    {
        path: PATHS.Global.ForgotPassword,
        element: ForgotPasswordLazy,
    },
    {
        path: PATHS.Global.ResetPassword,
        element: ResetPasswordLazy,
    },
    {
        path: PATHS.Global.ConfirmAccount,
        element: ConfirmAccountLazy,
    },
    {
        path: PATHS.Global.NotFound,
        element: NotFoundLazy,
    },
];

export const PortalWrapperLazy = React.lazy(() => import("../../containers/layout/wrappers/portalWrapper"));

export interface IPortalRouteElement {
    path: string;
    label: string;
    element: React.LazyExoticComponent<() => JSX.Element>;
    icon: ({ height, width }: IconSVGProps) => JSX.Element;
    notificationIcon?: ({ height, width }: IconSVGProps) => JSX.Element;
}

export const PortalRouteElements: IPortalRouteElement[] = [
    {
        path: PATHS.Portal.Dashboard,
        label: StringResources.mainNavigation.dashboard,
        element: DashboardLazy,
        icon: DashboardIcon,
    },
    {
        path: PATHS.Portal.Payments,
        label: StringResources.mainNavigation.payments,
        element: PaymentsLazy,
        icon: PaymentsIcon,
    },
    {
        path: PATHS.Portal.Settlements,
        label: StringResources.mainNavigation.settlements,
        element: SettlementsLazy,
        icon: SettlementsIcon,
    },
    {
        path: PATHS.Portal.MerchantSettlements,
        label: StringResources.mainNavigation.merchantSettlements,
        element: MerchantSettlementsLazy,
        icon: SettlementsIcon,
    },
    {
        path: PATHS.Portal.Agreements,
        label: StringResources.mainNavigation.agreements,
        element: AgreementsLazy,
        icon: AgreementsIcon,
    },
    {
        path: PATHS.Portal.MerchantAgreements,
        label: StringResources.mainNavigation.merchantAgreements,
        element: MerchantAgreementsLazy,
        icon: AgreementsIcon,
    },
    {
        path: PATHS.Portal.Transactions,
        label: StringResources.mainNavigation.transactions,
        element: TransactionsLazy,
        icon: TransactionsIcon,
    },
    {
        path: PATHS.Portal.Users,
        label: StringResources.mainNavigation.users,
        element: EmployeesLazy,
        icon: UsersIcon,
    },
];
