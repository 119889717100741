export const PATHS = {
    Global: {
        Login: "/login",
        ForgotPassword: "/forgot-password",
        ResetPassword: "/reset-password",
        ConfirmAccount: "/confirm-account",
        NotFound: "/not-found",
    },
    Portal: {
        Index: "/",
        Dashboard: "/",
        Payments: "/payments",
        Agreements: "/agreements",
        PartnerAgreementDetails: "/agreements/:partnerContractId",
        Settlements: "/settlements",
        MerchantSettlements: "/merchant-settlements",
        Transactions: "/transactions",
        Users: "/users",
        Notifications: "/notifications",
        Settings: "/settings",
        MerchantAgreements: "/merchant-agreements",
    },
};
