import BaseSelect, { Option } from "components/baseSelect/baseSelect";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Merchant } from "redux/models/globalModels";
import { RootState } from "redux/store";
import { MerchantFilterType } from "utils/enums";
import { StringResources } from "utils/language/languageResource";

interface IMerchantDropdownFilterProps {
    merchantFilter: MerchantFilterType;
    onMerchantChange: (merchantId?: number) => void;
    selectedMerchantId: number | undefined;
    size?: "small" | "default";
}

const MerchantDropdownFilter = (props: IMerchantDropdownFilterProps) => {
    const merchants = useSelector((state: RootState) => state.global.merchants);

    const getMerchantOptions = () => {
        const merchantOptions: Option[] = merchants.map((merchant: Merchant, index: number) => {
            return {
                value: merchant.id,
                label: merchant.name,
                selected: merchant.id == props.selectedMerchantId,
            };
        });

        if (props.merchantFilter === MerchantFilterType.All) {
            merchantOptions.unshift({
                value: undefined,
                label: `${t(StringResources.filter.allMerchants)}`,
                selected: props.selectedMerchantId === undefined,
            });
        }

        return merchantOptions;
    };

    const onSelectChange = (selectedItem?: Option) => {
        const selectedMerchantId = selectedItem?.value ? Number(selectedItem.value) : undefined;
        props.onMerchantChange(selectedMerchantId);
    };

    return <BaseSelect options={getMerchantOptions()} onChange={onSelectChange} size="sm" />;
};

export default MerchantDropdownFilter;
