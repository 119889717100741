import { IReportDetailsDefinition } from "api/requestModels";
import MerchantAgreementsQuickLinks from "pages/merchantAgreements/details/merchantAgreementsQuickLinks";
import { Fragment } from "react";
import { DetailSectionEnum } from "utils/enums";
import "./reportDetailsSections.scss";
import PaymentSettlementsBreakdown from "pages/payments/details/paymentSettlementsBreakdown";

interface IReportDetailsSectionsProps {
    detailsDefinitions: IReportDetailsDefinition<any>;
}

const ReportDetailsSections = ({ detailsDefinitions }: IReportDetailsSectionsProps) => {
    if (!detailsDefinitions.sections) {
        return <></>;
    }

    return (
        <>
            {detailsDefinitions.sections.map((detailSection, index) => (
                <Fragment key={index}>
                    {detailsDefinitions.sections!.length > 1 && index + 1 === detailsDefinitions.sections!.length && (
                        <div className="reportDetailsSections-separator"></div>
                    )}
                    <ReportDetailsSectionHandler section={detailSection} />
                </Fragment>
            ))}
        </>
    );
};

interface IReportDetailsSectionHandler {
    section: DetailSectionEnum;
}

const ReportDetailsSectionHandler = (props: IReportDetailsSectionHandler) => {
    switch (props.section) {
        case DetailSectionEnum.MerchantAgreementsQuickLinks:
            return <MerchantAgreementsQuickLinks />;
        case DetailSectionEnum.PaymentSettlementsBreakdown:
            return <PaymentSettlementsBreakdown />;
    }
};

export default ReportDetailsSections;
