import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    getFilterTypesThunk,
    getPartnerFilterTypesThunk,
    userGetMerchants,
    userGetPartnerContracts,
} from "redux/actions/globalActions";
import { GetMerchantResponse, IGlobalReducerState } from "redux/models/globalModels";
import { PayloadData } from "redux/models/userModels";
import { userGetPartnerSettings } from "redux/actions/globalActions";
import { GetPartnerSettingsResponse } from "redux/models/globalModels";

const initialState: IGlobalReducerState = {
    merchants: [],
    partnerContracts: [],
    filterTypes: {
        cardIssuers: [],
        cardTypes: [],
        currencyIds: [],
        settlementTypes: [],
        cardEntryModeTypes: [],
        authenticationMethodTypes: [],
        authorizationMethodTypes: [],
        settlementStatuses: [],
        feeTransactionCategoryTypes: [],
        contractStatuses: [],
    },
    partnerCreatedTime: "",
    partnerName: "",
    partnerSsn: "",
    partnerDescription: "",
    partnerNumber: "",
};

const reportReducer = createSlice({
    name: "global",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(userGetPartnerSettings.pending, state => {});
        builder.addCase(
            userGetPartnerSettings.fulfilled,
            (state, action: PayloadAction<PayloadData<GetPartnerSettingsResponse>>) => {
                state.partnerCreatedTime = action.payload.data.createdTime;
                state.partnerDescription = action.payload.data.description;
                state.partnerName = action.payload.data.name;
                state.partnerNumber = action.payload.data.partnerNumber;
                state.partnerSsn = action.payload.data.ssn;
            }
        );
        builder.addCase(userGetPartnerSettings.rejected, state => {
            state.partnerCreatedTime = "";
            state.partnerDescription = "";
            state.partnerName = "";
            state.partnerNumber = "";
            state.partnerSsn = "";
        });

        builder.addCase(userGetMerchants.pending, state => {});
        builder.addCase(
            userGetMerchants.fulfilled,
            (state, action: PayloadAction<PayloadData<GetMerchantResponse>>) => {
                state.merchants = action.payload.data.merchants.sort((a, b) => a.name.localeCompare(b.name));
            }
        );
        builder.addCase(userGetMerchants.rejected, state => {
            state.merchants = [];
        });

        builder.addCase(getFilterTypesThunk.fulfilled, (state, action) => {
            state.filterTypes = {
                ...state.filterTypes,
                ...action.payload,
            };
        });
        builder.addCase(userGetPartnerContracts.fulfilled, (state, action) => {
            state.partnerContracts = action.payload.data;
        });

        builder.addCase(getPartnerFilterTypesThunk.fulfilled, (state, action) => {
            state.partnerFilterTypes = action.payload;
        });
    },
});

export const {} = reportReducer.actions;
const { reducer } = reportReducer;
export default reducer;
