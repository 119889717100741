export enum SupportedLanguageEnum {
    Icelandic = 1,
    English = 2,
}

export enum LoginStateEnum {
    Uninitialized = 0,
    Pending = 1,
    LoggedIn = 2,
    Unauthorized = 3,
    Expired = 4,
}

export enum ModalTypeEnum {
    None = 0,
    ExportModal = 1,
    DeleteModal = 2,
    CreateEmployee = 3,
    DetailsModal = 6,
    FeedbackFormModal = 7,
    SpecialMenuColumnModal = 8,
    SpecialMenuFilterModal = 9,
    Confirm = 10,
}

export enum ModalActionButtonTypeEnum {
    Confirm = 0,
    Delete = 1,
}

export enum FilterTypeEnum {
    TextFilter = 1,
    DateFilter = 2,
    DropdownFilter = 3,
    MultiSelectFilter = 4,
    NumberFilter = 5,
    TotalSubtotal = 6,
    MerchantFilter = 7,
}

export enum DetailsRowTypeFormatterEnum {
    Text = 1,
    Currency = 2,
    Date = 3,
    DateTime = 4,
    Drill = 5,
    Id = 6,
    List = 7,
    Boolean = 8,
    ValueName = 9,
    ValueNameList = 10,
    TransactionRefundLabel = 11,
}

export enum DetailSectionEnum {
    MerchantAgreementsQuickLinks = 2,
    PaymentSettlementsBreakdown = 3,
}

export enum DetailHeaderEnum {
    None = 0,
    Default = 1,
    Payments = 2,
    MerchantSettlements = 3,
}

export enum ExportType {
    CSV = "1",
    XLSX = "2",
}

export enum ExportColumn {
    All = "0",
    VisibleColumns = "1",
}

export enum DropdownFilterNameEnum {
    AgreementNumbers = "agreementNumbers",
    CurrencyIds = "currencyIds",
    CardIssuers = "cardIssuers",
    CardTypes = "cardTypes",
    Periods = "periods",
    SettlementTypes = "settlementTypes",
    UserStatuses = "userStatuses",
    CardEntryModeTypes = "cardEntryModeTypes",
    AuthenticationMethodTypes = "authenticationMethodTypes",
    AuthorizationMethodTypes = "authorizationMethodTypes",
    ContractCurrencyIds = "contractCurrencyIds",
    SettlementStatuses = "settlementStatuses",
}

export enum RefundType {
    Full = 1,
    Partial = 2,
}

export enum TransactionType {
    Refund = 1,
    Purchase = 2,
}

export enum AuthorizationStatus {
    Approved = 1,
    Declined = 2,
}

export enum SettlementCardTypeRule {
    All = 1,
    Credit = 2,
    Debit = 3,
}

export enum MerchantFilterType {
    All = 1,
    OnlyMerchants = 2,
}

export enum AnnouncementType {
    Info = 1,
    Warning = 2,
    Error = 3,
}

export enum ContractStatusEnum {
    Active = 1,
    Inactive = 2,
}

export enum VersionEntityStatusEnum {
    Expired = 1,
    Current = 2,
    Pending = 3,
}
