import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import "./merchantSettlementsHeader.scss";

const MerchantSettlementsHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="settlements-header report-details__title">
            <div className="report-details__title--label">{`${t(
                StringResources.pages.merchantSettlements.title
            )}`}</div>
        </div>
    );
};

export default MerchantSettlementsHeader;
