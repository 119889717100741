import { createAsyncThunk } from "@reduxjs/toolkit";
import { basePaymentsRoute } from "pages/payments/api/paymentsApi";
import { IPaymentGetSettlementBreakdownRequest } from "redux/models/reportDetailsSectionModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const paymentSettlementBreakdownGet = createAsyncThunk<any, IPaymentGetSettlementBreakdownRequest>(
    "payment/getPaymentSettlementBreakdown",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: `${basePaymentsRoute}/get-payment-settlements-breakdown`,
            method: "GET",
            params: request,
        });
    }
);
