import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentSettlementBreakdownGet } from "redux/actions/reportDetailSectionActions";
import {
    IGetPaymentSettlementBreakdownResponse,
    IReportDetailSectionsState,
} from "redux/models/reportDetailsSectionModels";
import { PayloadData } from "redux/models/userModels";

const initialState: IReportDetailSectionsState = {
    settlementIsLoading: false,
    paymentIsLoading: false,
    settlementsBreakdown: [],
    settlementsBreakdownMap: [],
};

export const reportDetailSectionReducer = createSlice({
    name: "reportDetailSection",
    initialState,
    reducers: {
        setBreakdownsDataFromMap(
            state: IReportDetailSectionsState,
            action: PayloadAction<IGetPaymentSettlementBreakdownResponse>
        ) {
            state.settlementsBreakdown = action.payload.settlementsBreakdowns;
        },
    },
    extraReducers: builder => {
        builder.addCase(paymentSettlementBreakdownGet.pending, state => {
            state.paymentIsLoading = true;
        });
        builder.addCase(
            paymentSettlementBreakdownGet.fulfilled,
            (state, action: PayloadAction<PayloadData<IGetPaymentSettlementBreakdownResponse>>) => {
                state.settlementsBreakdown = action.payload.data.settlementsBreakdowns;

                let data = {} as IGetPaymentSettlementBreakdownResponse;
                data.paymentId = action.payload.data.paymentId;
                data.settlementsBreakdowns = action.payload.data.settlementsBreakdowns;

                state.paymentIsLoading = false;
                state.settlementsBreakdownMap.push(data);
                if (state.settlementsBreakdownMap.length > 25) {
                    state.settlementsBreakdownMap.shift();
                }
            }
        );
        builder.addCase(paymentSettlementBreakdownGet.rejected, state => {
            state.paymentIsLoading = false;
        });
    },
});

export const { setBreakdownsDataFromMap } = reportDetailSectionReducer.actions;

const { reducer } = reportDetailSectionReducer;
export default reducer;
