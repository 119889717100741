import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userChangeDefaultLanguage, userChangePassword, userGetSettings } from "redux/actions/userActions";
import { GetSettingsResponse, IChangeDefaultLanguageRequest, IUserState, PayloadData } from "redux/models/userModels";
import { SupportedLanguageEnum } from "utils/enums";
import { PageTypeEnum } from "utils/reportDefinitions";

const initialState: IUserState = {
    language: undefined,
};

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(userGetSettings.pending, state => {});
        builder.addCase(userGetSettings.fulfilled, (state, action: PayloadAction<PayloadData<GetSettingsResponse>>) => {
            state.language = SupportedLanguageEnum[action.payload.data.languageType];

            // clear users local storage of the browser if be is null

            if (action.payload.data.partnerUserColumnSelection.length == 0) {
                var pageTypeEnumValues = Object.values(PageTypeEnum);

                pageTypeEnumValues.forEach(element => {
                    if (localStorage.getItem(element) != null) {
                        localStorage.removeItem(element);
                    }
                });
            } else {
                action.payload.data.partnerUserColumnSelection.forEach(element => {
                    localStorage.setItem(element.pageTypeKey, element.columns);
                });
            }
        });
        builder.addCase(userGetSettings.rejected, state => {});

        builder.addCase(userChangeDefaultLanguage.pending, state => {});
        builder.addCase(
            userChangeDefaultLanguage.fulfilled,
            (state, action: PayloadAction<IChangeDefaultLanguageRequest>) => {
                state.language = SupportedLanguageEnum[action.payload.languageType];
            }
        );
        builder.addCase(userChangeDefaultLanguage.rejected, state => {});

        builder.addCase(userChangePassword.pending, state => {});
        builder.addCase(userChangePassword.fulfilled, state => {});
        builder.addCase(userChangePassword.rejected, state => {});
    },
});

const { reducer } = userReducer;
export const {} = userReducer.actions;
export default reducer;
