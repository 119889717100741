import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    GetDashboardDataRequest,
    GetDashboardTurnoverTrendRequest,
    ISetSalesTarget,
} from "redux/models/dashboardModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const dashboardDataGet = createAsyncThunk<any, GetDashboardDataRequest>(
    "dashboard/getDashboardData",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-dashboard-data",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                partnerContractId: request.partnerContractId,
                requestedMonth: request.requestedMonth,
            },
        });

        return {
            ...response.data,
            contractId: request.partnerContractId,
            requestedMonth: request.requestedMonth,
        };
    }
);

export const dashboardSetSalesTarget = createAsyncThunk<any, ISetSalesTarget>(
    "dashboard/setSalesTarget",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/set-sales-target",
            method: "POST",
            data: {
                salesTargetAmount: request.salesTargetAmount,
            },
        });

        return response.data;
    }
);

export const turnoverTrendData = createAsyncThunk<any, GetDashboardTurnoverTrendRequest>(
    "dashboard/turnoverTrendData",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/turnover-trend",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                partnerContractId: request.partnerContractId,
            },
        });
    }
);
